import React, { useEffect } from 'react';
import { useSelector , useDispatch } from 'react-redux';
import DataTable from '../../CmsComponents/DataTable/DataTable';
import {GetAllSponsors} from '../../features/SponsorSlice/SponsorSlice'

const SponsorsPage = () => {
  const dispatch = useDispatch()

  const SponsorsColoumns = [
    { field: 'fullName', headerName: 'Full Name', width: 200,},
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'contact', headerName: 'Contact', width: 200 },
    { field: 'paymentStatus', headerName: 'Payment Status', width: 200},
    { field: 'amount', headerName: 'Amount', width: 200},
    { field: 'registrationType', headerName: 'Registration Type', width: 200},
    { field: 'createdAt', headerName: 'CreatedAt', width: 200},
  ];
  const {sponsors} = useSelector((store)=>store.sponsors)

  useEffect(()=>{
    dispatch(GetAllSponsors())
    
  },[dispatch])

  return (
    <div>
      {/* <h3 style={{padding:'2rem'}}>Sponsor</h3> */}
      <DataTable columns={SponsorsColoumns} data={sponsors}/>
    </div>
  )
}

export default SponsorsPage