import './VenueComp.css'
import {AiOutlineArrowRight} from 'react-icons/ai'
import { Link } from 'react-router-dom'
import GetHomeState from '../../Utils/GetHomeState'
import GetAboutState from '../../Utils/GetAboutState'
import GetColorState from '../../Utils/GetColorState'

const VenueComp = () => {
  const { Home } = GetHomeState();
  const { About } = GetAboutState();
  const { Color } = GetColorState();


  return (
    <div className='flex_row_sb venueDiv'>
          <div className='ticketDiv_left venue_left'>
            <h2>The Venue</h2>
            <p>{Home.venueAddress}</p>
            <Link style={{color:Color.secondaryBackgroundColor}} to="/contact" className='secondary_btn venueBtn underLine'>View On Map <AiOutlineArrowRight/></Link>
          </div>
          <div className='venue_right'>
            <img src={About.aboutImg1} alt='about image'></img>
          </div>
    </div>
  )
}
export default VenueComp