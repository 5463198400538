import "./HeaderSimple.css";
import {useState,useEffect} from "react";
import {Link} from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css';
import GetHomeState from '../../Utils/GetHomeState'
import GetColorState from '../../Utils/GetColorState'



const Header = () => {
  const { Home } = GetHomeState();
  const { Color } = GetColorState();
  const [showH, setShowH] = useState(false);

const handleScroll = () => {
  const position = window.pageYOffset;
  if(position>100){
    setShowH(true)
  }else{
    setShowH(false)
  }
};

useEffect(() => {
  window.addEventListener('scroll', handleScroll, { passive: true });
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, [window.pageYOffset]);

return (
<>{showH===true?<>
  <div style={{backgroundColor: Color.secondaryBackgroundColor, color: Color.secondaryTextColor}} className='header-simple'>
    <div style={{color: Color.secondaryTextColor}} className="header_left">
      <img className="" src={Home.eventLogo} alt="logo"/>
    </div>
    <div style={{color: Color.secondaryTextColor}} className="header_right">
      <Link to="/">HOME</Link>
      <Link to="/about">ABOUT</Link>
      <Link to="/event">EVENT</Link>
      <Link to="/speakers">SPEAKERS</Link>
      <Link to="/contact">CONTACT</Link>
      <Link to="/register-sponsor">SPONSOR</Link>
    </div>
    <Link style={{backgroundColor: Color.primaryBackgroundColor, color: Color.primaryTextColor}} className="btn" to="/register-delegate">REGISTER</Link>
  </div>
</>:null}</>
  )
}
export default Header;