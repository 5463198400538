import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GetAllAbout } from '../features/AboutCmsSlice/AboutCmsSlice'

function GetAboutState() {
    const { About, isSuccess } = useSelector((store) => store.aboutCms);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GetAllAbout());
    }, [isSuccess, dispatch]);
return { About, isSuccess };
}
export default GetAboutState;