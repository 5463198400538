import React ,{useState}from 'react'
import './MobileNavDash.css' ;
import {Link} from 'react-router-dom'

const MobileNavDash = ({url}) => {
    const [show, setShow] = useState(false)
    const [firstShow, setFirstShow] = useState(true)


    const showFunction =()=>{
      setFirstShow(false)
      setShow(!show)
    }


  return (
    <div className="Mob_NavbarDash">
        <div className={show?"Mob_Navbar_rightDash":(firstShow===false && show===false)?'hidden_animation':"hidee"}>
            <Link  to='/' onClick={showFunction}>HOME PAGE</Link>
            <Link to="/dashboard/home" onClick={showFunction}>Home</Link>
            <Link to="/dashboard/about" onClick={showFunction}>About</Link>
            <Link to="/dashboard/footer" onClick={showFunction}>Footer</Link>
            <Link to="/dashboard/event" onClick={showFunction}>Event</Link>
            <Link to="/dashboard/speaker" onClick={showFunction}>Speakers</Link>
            <Link to="/dashboard/partner" onClick={showFunction}>Partners</Link>
            <Link to="/dashboard/prevYearSpeaker" onClick={showFunction}>Previous Speakers</Link>
            <Link to="/dashboard/delegateTicket" onClick={showFunction}>Delegate Ticket</Link>
            <Link to="/dashboard/sponsorTicket" onClick={showFunction}>Sponsor Ticket</Link>
            <Link to="/dashboard/delegate" onClick={showFunction}>All Delegates</Link>
            <Link  to='/dashboard/sponsor' onClick={showFunction}>All Sponsors</Link>
        </div>
        <div className={show?'crossDash burgerDash':'burgerDash'} onClick={showFunction}>
            <div></div>
            <div></div>
        </div>
       
    </div>
  )
}

export default MobileNavDash

