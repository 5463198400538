import React from 'react'
import './Header.css'
import {Link, useNavigate} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {logout,reset} from '../../features/AuthSlice/AuthSlice'
import MobileNavDash from '../MobileNavDash/MobileNavDash'


const Header = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { user }=useSelector((store)=>store.auth)

  const onLogout =()=>{
    dispatch(logout())
    dispatch(reset())
    navigate('/login');
  }


  return (
    <div className='headerDash'>
      <MobileNavDash/>

      <div className='header_leftDash'>
        <h5>Dashboard Overview</h5>
      </div>
      <div className="header_rightDash">
      <ul>
        {user ? ( 
          <button className='btn' onClick={onLogout}>Logout</button>
        ) : (
          <>
          <li>
          <Link to='/login'>Login</Link>
          <button className='btn' onClick={()=>navigate('/register')}>Get Started</button>
        </li>
        </>
        )}  
      </ul>
      </div>
    </div>
  )
}

export default Header