import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GetAllDelegateTicketCms } from '../features/DelegateTicketCmsSlice/DelegateTicketCmsSlice'

function GetDelegateTicketState() {
    const { DelegateTicketCms, isSuccess } = useSelector((store) => store.delegateTicketCms);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GetAllDelegateTicketCms());
    }, [isSuccess, dispatch]);
return {DelegateTicketCms, isSuccess };
}
export default GetDelegateTicketState;