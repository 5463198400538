import Header from "../../Components/Header/Header"
import '../FormDelegate/FormDelegate.css'
import React ,{useState} from "react"
import {registerSponsor} from '../../features/Register/Register'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GetSponsorTicketState from '../../Utils/GetSponsorTicketState'
import GetColorState from '../../Utils/GetColorState'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'


const FormSponsor = () => {
  const dispatch = useDispatch()
  const { Color } = GetColorState();
  const { SponsorTicketCms } = GetSponsorTicketState();

  const [data, setData] = useState({
    email:'',fullName:'',
    contact:'',
    organizationName:'',
    registrationType:'',
    amount:'',
  })

  const ValueUpdater = (e)=>{
    setData({...data , [e.target.name]:e.target.value})
  }

  const ValueUpdater1 = (value) => {
    setData({ ...data, contact: value });
  };


  const handleRadioChange = (event) => {
    const selectedValue = event.target.value;
    const selectedPrice = SponsorTicketCms.find(
      (type) => type.SponsorTicketName === selectedValue
    );
    if (selectedPrice) {
      setData({ ...data, registrationType: selectedValue, amount: selectedPrice.SponsorTicketPrice });
    }
  };

  const submit =async (e)=>{
    e.preventDefault()
    const {email,fullName,contact,organizationName,registrationType} = data
    if(email==='' || fullName==='' || organizationName===''|| registrationType==='' || contact===''){
      toast.info('Please fill all fields')
    }
    else{
      dispatch(registerSponsor(data))
    }
  }

  return (
    <div className="RigisterFormParent">
      <Header/>
      <div className='speaker_text header-register'>
        <h2>Register as a Sponsor</h2>
      </div>
      
      <div className="formParent">
      <form className="registrationForm" encType="multipart/form-data">
        <div className="formInputs">
          <div>
          <label htmlFor="email">Email Address *</label>
          <input type="email" id="email" name='email' value={data.email} onChange={ValueUpdater}/>
          </div>

          <div style={{display:'flex', flexDirection:'column',justifyContent:'initial'}}>
          <label htmlFor="contact">Mobile *</label>
          <PhoneInput inputStyle={{width:'100%', height:'2.5rem', borderRadius:'3px'}} country={'no'} countryCodeEditable={true} placeholder="Enter phone number" value={data.contact} onChange={ValueUpdater1}/>
          </div>

          <div>
          <label htmlFor="FullName">Full Name *</label>
          <input type="text" id="fullName" name='fullName' value={data.fullName} onChange={ValueUpdater}/>
          </div>

          <div>
          <label htmlFor="OrganizationsName">Organizations Name *</label>
          <input type="text" id="organizationName" name='organizationName' value={data.organizationName} onChange={ValueUpdater}/>
          </div>
        </div>

        <div className="formOptions">
        <div className="radioDiv">
          <p>Select Sponsor Type *</p>

          <div>
          {SponsorTicketCms.map((type,index) => (
            <div key={index}>
              <input
                type="radio"
                name="registrationType"
                value={type.SponsorTicketName}
                onChange={handleRadioChange}
              />
              <label htmlFor={type.SponsorTicketName}>{type.SponsorTicketName} (${type.SponsorTicketPrice})</label>
            </div>
            ))}



          <input type="text" placeholder="Amount" name="amount" onChange={ValueUpdater} value={data.amount}/>


          </div>
        </div>
        </div>
        <div style={{textAlign:'left'}}>
          <button style={{backgroundColor: Color.secondaryBackgroundColor, color: Color.secondaryTextColor}} type="submit" className="btn" onClick={submit}>Submit</button>
        </div>
      </form>
      </div>
    </div>
  )
}

export default FormSponsor;