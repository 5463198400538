import React, { useEffect,useState } from 'react'
import { useSelector , useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import {toast} from 'react-toastify'
// import '../CareerPage/CareerPage.css'
import { useLocation, useNavigate } from 'react-router-dom';
import {UpdateAllHome,reset, GetAllHome} from '../../features/HomeCmsSlice/HomeCmsSlice'



const UpdateHome = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location  = useLocation();
    const id = location.pathname.substring(location.pathname.lastIndexOf('/')+1)
    const [data, setData] = useState({
        eventLogo: null,
        companyLogo: null,
        homeBanner:null,
        eventName: '',
        eventCollab: '',
        companyName:'',
        eventDate: '',
        eventCity: '',
        eventCountry: '',
        aboutHeading: '',
        aboutContent: '',
        previousHeading: '',
        previousContent: '',
        motiveHeading: '',
        motiveContent: '',
        venueAddress: '',
    });

const handleChange =(e)=>{
    setData({ ...data, [e.target.id]: e.target.value });
}

const sendForm = (e)=>{
    e.preventDefault()
    if (data.eventName !== '' && data.eventCollab !== '' && data.companyName !== '' && data.eventDate !== '' && data.eventCity !== '' && data.eventCountry !== '' && data.aboutHeading !== '' && data.aboutContent !== '' && data.previousHeading !== '' && data.previousContent !== '' && data.motiveHeading !== '' && data.motiveContent !== '' && data.venueAddress !== '' && data.eventLogo !== null && data.companyLogo !== null) {
        const formData = new FormData();
        formData.append('eventName', data.eventName);
        formData.append('eventCollab', data.eventCollab);
        formData.append('companyName', data.companyName);
        formData.append('eventDate', data.eventDate);
        formData.append('eventCity', data.eventCity);
        formData.append('eventCountry', data.eventCountry);
        formData.append('aboutHeading', data.aboutHeading);
        formData.append('aboutContent', data.aboutContent);
        formData.append('previousHeading', data.previousHeading);
        formData.append('previousContent', data.previousContent);
        formData.append('motiveHeading', data.motiveHeading);
        formData.append('motiveContent', data.motiveContent);
        formData.append('venueAddress', data.venueAddress);

        if (data.eventLogo !== null || data.companyLogo !== null || data.homeBanner !== null) {
            formData.append('eventLogo', data.eventLogo);
            formData.append('companyLogo', data.companyLogo);
            formData.append('homeBanner', data.homeBanner);
            dispatch(UpdateAllHome({ formData, id }))
            .then(() => {
              // After creating the speaker, call GetAllHome API to refresh the list
              dispatch(GetAllHome());
              navigate('/dashboard/home');
            })
            .catch((error) => {
              // Handle any errors that occur during speaker creation
              toast.error('Error updating speaker: ' + error.message);
            });
        } else {
          toast('Please upload the profile image.');
        }
    
      } else {
        toast('Please fill out the form completely.');
      }
    }
let {Home, isSuccess} = useSelector((store)=>store.homeCms)
Home = Home ? Home : null;

useEffect(()=>{
    dispatch(GetAllHome())
    if(isSuccess && Home){
        setData({ eventName:Home.eventName, eventCollab:Home.eventCollab, companyName:Home.companyName, eventDate:Home.eventDate, eventCity:Home.eventCity, eventCountry:Home.eventCountry, aboutHeading:Home.aboutHeading, aboutContent:Home.aboutContent, previousHeading:Home.previousHeading, previousContent:Home.previousContent, motiveHeading:Home.motiveHeading, motiveContent:Home.motiveContent, venueAddress:Home.venueAddress, eventLogo:null, companyLogo:null, homeBanner:null })
    }
},[isSuccess])

return (
    <form className='CreateCareer'> 
    <div className="form_left">
        <TextField id="eventName" value={data.eventName} label="Event Name" variant="outlined" onChange={(e)=>{handleChange(e)}}/>
        <TextField id="eventCollab" value={data.eventCollab} label="Event Collaboration" variant="outlined" onChange={(e)=>{handleChange(e)}}/>
        <TextField id="companyName" value={data.companyName} label="Company Name" variant="outlined" onChange={(e)=>{handleChange(e)}}/>
        <TextField type='date' id="eventDate" value={data.eventDate} label="Event Date" variant="outlined" onChange={(e)=>{handleChange(e)}}/>
        <TextField id="eventCity" value={data.eventCity} label="Event City" variant="outlined" onChange={(e)=>{handleChange(e)}}/>
        <TextField id="eventCountry" value={data.eventCountry} label="Event Country" variant="outlined" onChange={(e)=>{handleChange(e)}}/>
        <div style={{display:'flex',flexDirection:'column' , gap:'0.5rem'}}>
            <label htmlFor="homeBanner">Home Banner Image</label>
            <input id="homeBanner" label="Home Banner Image" type="file" accept="image/*" onChange={(e)=>setData({...data,["homeBanner"]:e.target.files[0]})}/>
        </div>
        <TextField id="aboutHeading" value={data.aboutHeading} label="About Heading" variant="outlined" onChange={(e)=>{handleChange(e)}}/>
        <TextField id="aboutContent" value={data.aboutContent} label="About Content" variant="outlined" onChange={(e)=>{handleChange(e)}}/>
        <TextField id="previousHeading" value={data.previousHeading} label="Previous Heading" variant="outlined" onChange={(e)=>{handleChange(e)}}/>
        <TextField id="previousContent" value={data.previousContent} label="Previous Content" variant="outlined" onChange={(e)=>{handleChange(e)}}/>
        <div style={{display:'flex',flexDirection:'column' , gap:'0.5rem'}}>
            <label htmlFor="eventLogo">Event Logo</label>
            <input id="eventLogo" label="Event Logo" type="file" accept="image/*" onChange={(e)=>setData({...data,["eventLogo"]:e.target.files[0]})}/>
        </div>
        <div style={{display:'flex',flexDirection:'column' , gap:'0.5rem'}}>
            <label htmlFor="companyLogo">Company Logo</label>
            <input id="companyLogo" label="Company Logo" type="file" accept="image/*" onChange={(e)=>setData({...data,["companyLogo"]:e.target.files[0]})}/>
        </div>
        <TextField id="motiveHeading" value={data.motiveHeading} label="Motive Heading" variant="outlined" onChange={(e)=>{handleChange(e)}}/>
        <TextField id="motiveContent" value={data.motiveContent} label="Motive Content" variant="outlined" onChange={(e)=>{handleChange(e)}}/>
        <TextField id="venueAddress" value={data.venueAddress} label="Venue Address" variant="outlined" onChange={(e)=>{handleChange(e)}}/>
    </div>
    <div style={{ textAlign: 'left' }}>
        <button className='btn' onClick={(e)=>sendForm(e)}>Submit</button>
    </div>
</form>
)
}

export default UpdateHome