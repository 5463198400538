import React from 'react'
import GetColorState from '../../Utils/GetColorState'

const AgendaDot1 = () => {
  const { Color } = GetColorState();

  return (
    <div className='agendaDot1'>
        <svg width="357" height="347" viewBox="0 0 357 347" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M109.996 74.6128L119.476 182.115L297.212 242.775L307.463 265.386" stroke="black" strokeWidth="1.05328" strokeDasharray="5.27 5.27"/>
        <path d="M137.509 55.3701C127.51 40.0145 106.957 35.6716 91.601 45.67C76.2453 55.6684 71.9024 76.2219 81.9008 91.5775C91.8992 106.933 112.453 111.276 127.808 101.278C143.164 91.2792 147.507 70.7257 137.509 55.3701Z" fill={`${Color.secondaryBackgroundColor}`}/>
        <path d="M303.887 236.957C301.03 232.57 295.158 231.329 290.77 234.186C286.383 237.042 285.142 242.915 287.999 247.302C290.856 251.689 296.728 252.93 301.115 250.074C305.503 247.217 306.743 241.344 303.887 236.957Z" fill={`${Color.secondaryBackgroundColor}`}/>
        <path d="M127.581 174.925C124.725 170.538 118.852 169.297 114.465 172.154C110.078 175.01 108.837 180.883 111.693 185.27C114.55 189.657 120.423 190.898 124.81 188.042C129.197 185.185 130.438 179.313 127.581 174.925Z" fill={`${Color.secondaryBackgroundColor}`}/>
        </svg>
    </div>
  )
}

export default AgendaDot1
