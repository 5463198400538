import React, { useEffect } from 'react';
import { useSelector , useDispatch } from 'react-redux';
import {GetAllFooter , filterState} from '../../features/FooterCmsSlice/FooterCmsSlice'
import DataTable from '../../CmsComponents/DataTable/DataTable';
import {RiDeleteBin7Line} from 'react-icons/ri'
import {BiEditAlt} from 'react-icons/bi'
import { Link } from 'react-router-dom';


const FooterPage = () => {
  const dispatch = useDispatch()

  const {Footer} = useSelector((store)=>store.footerCms)

  useEffect(()=>{
    dispatch(GetAllFooter())
  },[dispatch])

return (
    <div className='home_pageCms'>
      <div style={{display:'flex', justifyContent:'flex-end',alignItems:'center',gap:'0.2rem',textAlign:'right', marginBottom:'1.5rem'}}>
        <Link style={{display:'flex', justifyContent:'center',alignItems:'center',gap:'0.2rem'}} className="btn animate-element smallPadding" to={`/dashboard/updateFooter/${Footer._id}`}>Edit<BiEditAlt/></Link>
      </div>

    {Footer && (
      <div>

        <div className='containerCms'>
          <h6><b>Instagram: </b>{Footer.instaHandle}</h6>
        </div>
        <div className='containerCms'>
          <h6><b>LinkedIn: </b>{Footer.linkedinHandle}</h6>
        </div>
        <div className='containerCms'>
          <h6><b>Email: </b>{Footer.mailHandle}</h6>
        </div>
        <div className='containerCms'>
          <h6><b>Privacy Policy: </b>{Footer.privacyPolicy}</h6>
        </div>
        <div className='containerCms'>
          <h6><b>Consent: </b>{Footer.consent}</h6>
        </div>
        <div className='containerCms'>
          <h6><b>Info We Collect: </b>{Footer.infoWeCollect}</h6>
        </div>
        <div className='containerCms'>
          <h6><b>Info Use: </b>{Footer.infoUse}</h6>
        </div>
        <div className='containerCms'>
          <h6><b>CCPA: </b>{Footer.CCPA}</h6>
        </div>
        <div className='containerCms'>
          <h6><b>GDPR: </b>{Footer.GDPR}</h6>
        </div>
        <div className='containerCms'>
          <h6><b>Children Information: </b>{Footer.childrenInfo}</h6>
        </div>
        <div className='containerCms'>
          <h6><b>Changes To Privacy Policy: </b>{Footer.changesToPrivacyPolicy}</h6>
        </div>
        {/* <div className='containerCms'>
          <h6><b>Accessibility: </b>{Footer.accessibility}</h6>
        </div> */}
        {/* <div className='containerCms'>
          <h6><b>FAQ: </b>{Footer.FAQ}</h6>
        </div> */}
        {/* <Link className='containerCms' to={`/dashboard/updateFooter/${Footer._id}`} style={{display:'flex'}}>
          Edit<BiEditAlt style={{ fontSize: "1.5rem", color: "#3166BB", cursor: "pointer" }} />
        </Link> */}
      </div>
    )}

    {/* <DataTable columns={FooterColumns} data={Footer} */}
  </div>

  )
}

export default FooterPage