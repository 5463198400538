import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const initialState = {
    Footer:'',
    message:'',
    isLoading:false,
    isError:false,
    isSuccess:false,
}


////////////////////////////Get All Footer////////////////
export const GetAllFooter = createAsyncThunk('Footer/getCmsFooter', async(_, thunkAPI)=>{
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/getCmsFooter`
        const response = await axios.get(API_URL)
        return response.data
 
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})


export const UpdateAllFooter = createAsyncThunk('Footer/UpdateCmsFooter', async({data,id}, thunkAPI)=>{

    try{
        const token = thunkAPI.getState().auth.user.token
        const config ={
           headers:{
               Authorization: `Bearer ${token}`
           }
       }
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/updateCmsFooter/${id}`
        const response = await axios.patch(API_URL, data, config)
        return response.data
 
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})



export const FooterSlice = createSlice({
    name:'FooterSlice',
    initialState,
    reducers:{
        reset: (state)=> initialState,
        filterState:(state,action)=>{            
            state.Footer = state.Footer.filter((item) => item._id !== action.payload)
        }
    },
    extraReducers:(builder)=>{
        builder
        .addCase(GetAllFooter.pending, (state, action)=>{
            state.isLoading = true
        })
        .addCase(GetAllFooter.fulfilled, (state, action)=>{
            state.isLoading = false
            state.message = action.payload.message
            state.isSuccess = true
            if(action.payload.success===true){
                state.Footer = action.payload.cmsFooter
            }
        })
        .addCase(GetAllFooter.rejected, (state, action)=>{
            state.isLoading = false
            state.isError=true
            state.message = action.payload
            state.Footer={_id:"",instaHandle:"",linkedinHandle:"",mailHandle:"",privacyPolicy:"",consent:"",infoWeCollect:"",infoUse:"",CCPA:"",GDPR:"",childrenInfo:"",changesToPrivacyPolicy:"",accessibility:"",FAQ:""}
        })

        .addCase(UpdateAllFooter.pending, (state, action)=>{
            state.isLoading = true
        })
        .addCase(UpdateAllFooter.fulfilled, (state, action)=>{
            state.isSuccess=true
            state.isLoading = false
            state.message = action.payload.message
               if(action.payload.success===true){
                state.Footer = action.payload.updatedFooter
                toast(state.message)
            }
        })
        .addCase(UpdateAllFooter.rejected, (state, action)=>{
            state.isSuccess=false
            state.isLoading = false
            state.isError=true
            state.message = action.payload
            toast(action.payload)
            state.Footer={_id:"",instaHandle:"",linkedinHandle:"",mailHandle:"",privacyPolicy:"",consent:"",infoWeCollect:"",infoUse:"",CCPA:"",GDPR:"",childrenInfo:"",changesToPrivacyPolicy:"",accessibility:"",FAQ:""}
        })
    }
})


export const {reset,filterState} = FooterSlice.actions
export default FooterSlice.reducer