import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    sponsors:[],
    messageUS:'',
    isLoading:false,
    isError:false,
    isSuccessUs:false

}


// Get All Sponsors
export const GetAllSponsors = createAsyncThunk('sponsor/GetAllSponsor', async(_, thunkAPI)=>{
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/getAllSponsor`;

        const response = await axios.get(API_URL)
        return response.data
 
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})


export const sponsorslice = createSlice({
    name:'sponsorslice',
    initialState,
    reducers:{
        reset: (state)=> initialState,
        filterState:(state,action)=>{     
            state.sponsors = state.sponsors.filter((item) => item._id !== action.payload)
        }
    },
    extraReducers:(builder)=>{
        builder
        .addCase(GetAllSponsors.pending, (state, action)=>{
            state.isLoading = true
        })
        .addCase(GetAllSponsors.fulfilled, (state, action)=>{
            state.isLoading = false
            state.messageUS = action.payload.message
            if(action.payload && action.payload.length > 0){
            state.sponsors = action.payload
            }else{
                state.sponsors = []
            }
        })
        .addCase(GetAllSponsors.rejected, (state, action)=>{
            state.isLoading = false
            state.isError=true
            state.messageUS = action.payload
            state.sponsors=[]
        })   
    }
})


export const {reset,filterState} = sponsorslice.actions
export default sponsorslice.reducer