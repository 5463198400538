import React from 'react';
import {Link} from "react-router-dom";
import "./Sponsorship.css";
import GetSponsorTicketState from '../../Utils/GetSponsorTicketState'
import GetColorState from '../../Utils/GetColorState'


const Sponsorship = () => {
  const { Color } = GetColorState();
  const { SponsorTicketCms } = GetSponsorTicketState();

  return (
    <div style={{backgroundColor:Color.primaryBackgroundColor}} className='sponsorhip-section'>
        <div style={{color:Color.primaryTextColor}} className='pkgHeading'>
            <h4>Become a Partner with us</h4>
        </div>
        {SponsorTicketCms.length > 0 ? (
        <div className='cardParentDiv'>
        {SponsorTicketCms.map((type,index) => (
    <div key={index} className="spnsorship-card">
        <div className='card_headings'>
            <div className='card_heading1'>
                <h5>{type.SponsorTicketName}</h5>
                <div style={{color: Color.secondaryBackgroundColor}} className='card_heading1Underline'></div>
            </div>
            <div className='card_heading2'>
                <h3>{`$${type.SponsorTicketPrice}`}</h3>
            </div>
        </div>
        {type.perks && type.perks.length > 0 && (
          <ul className='ul listPerks'>
          {type.perks.map((perk, index) => (
            <li key={index}>{perk}</li>
          ))}
          </ul>
        )}
        <Link style={{backgroundColor: Color.secondaryBackgroundColor, color: Color.secondaryTextColor}} to="/register-sponsor" className=" btn sponsorship-btn">Partner Now</Link>
    </div>
        ))}
    </div>
          ):<h5 style={{textAlign:"center"}}>TBH Soon</h5>}
    </div>
  )
}

export default Sponsorship