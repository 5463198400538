import Header from "../../Components/Header/Header"
import "./FormDelegate.css"
import React ,{useState} from "react"
import {RiFacebookCircleLine, RiLinkedinLine, RiTwitterLine, RiInstagramLine} from 'react-icons/ri'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {registerDelegate} from '../../features/Register/Register'
import { useDispatch } from 'react-redux'
import GetHomeState from '../../Utils/GetHomeState'
import GetDelegateTicketState from '../../Utils/GetDelegateTicketState'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import GetColorState from '../../Utils/GetColorState'


const FormDelegate = () => {
  const { Home } = GetHomeState();
  const { Color } = GetColorState();
  const { DelegateTicketCms } = GetDelegateTicketState();

  const dispatch = useDispatch()



const [data, setData] = useState({
  email:'',fullName:'',
  contact:"",
  organizationType:'',
  organizationName:'',
  participationType:'',
  participationReason:'',
  contribute:'',
    profileImg:'',
    socialMedia_fb:'',
   socialMedia_ln:'',
   socialMedia_in:'',
   socialMedia_tw:'',
   amount:'',
   registrationType:'',
   person:'delegate'
})



const ValueUpdater = (e)=>{
  setData({...data , [e.target.name]:e.target.value})
}

const ValueUpdater1 = (value) => {
  setData({ ...data, contact: value });
};


const handleRadioChange = (event) => {
  const selectedValue = event.target.value;
  const selectedPrice = DelegateTicketCms.find(
    (type) => type.DelegateTicketName === selectedValue
  );
  if (selectedPrice) {
    setData({ ...data, registrationType: selectedValue, amount: selectedPrice.DelegateTicketPrice });
  }
};



    


    const submit =async (e)=>{
      e.preventDefault()
      const formData = new FormData()
      formData.append('email', data.email)
      formData.append('fullName', data.fullName)
      formData.append('contact', data.contact)
      formData.append('organizationName', data.organizationName)
      formData.append('organizationType', data.organizationType)
      formData.append('participationType', data.participationType)
      formData.append('participationReason', data.participationReason)
      formData.append('contribute', data.contribute)
      formData.append('profileImg', data.profileImg)
      formData.append('socialMedia_fb', data.socialMedia_fb)
      formData.append('socialMedia_ln', data.socialMedia_ln)
      formData.append('socialMedia_in', data.socialMedia_in)
      formData.append('socialMedia_tw', data.socialMedia_tw)
      formData.append('registrationType', data.registrationType)
      formData.append('amount', data.amount)
      formData.append('person', data.person)


      const {email,fullName,organizationName,organizationType,participationType,participationReason,contribute,profileImg, registrationType, amount} = data
      if(email==='' || fullName==='' || organizationName===''|| organizationType==='' || participationType===''|| participationReason===''|| contribute==='' || profileImg==='' ||registrationType==='' || amount===""){
        toast.info('Please fill all fields')
      }
      else{
          dispatch(registerDelegate(formData))
      }
    }

  return (
    <div className="RigisterFormParent">
      <Header/>
      <div className='speaker_text header-register'>
        <h2>Register as a Delegate</h2>
      </div>
      
      <div className="formParent">
      <form className="registrationForm" encType="multipart/form-data">
        <div className="formInputs">
          <div>
          <label htmlFor="email">Email Address *</label>
          <input type="email" id="email" name='email' value={data.email} onChange={ValueUpdater}/>
          </div>

          <div style={{display:'flex', flexDirection:'column',justifyContent:'initial'}}>
          <label htmlFor="contact">Mobile *</label>
          <PhoneInput inputStyle={{width:'100%', height:'2.5rem', borderRadius:'3px'}} country={'no'} countryCodeEditable={true} placeholder="Enter phone number" value={data.contact} onChange={ValueUpdater1}/>
          </div>

          <div>
          <label htmlFor="FullName">Full Name *</label>
          <input type="text" id="fullName" name='fullName' value={data.fullName} onChange={ValueUpdater}/>
          </div>

          <div>
          <label htmlFor="OrganizationsName">Organizations Name *</label>
          <input type="text" id="organizationName" name='organizationName' value={data.organizationName} onChange={ValueUpdater}/>
          </div>

        </div>

        

        <div className="formOptions">
        <div className="radioDiv">
          <p>Organization Type *</p>

          <div>
          <div>
            <input type="radio" id="Government" name="organizationType" value="Government" onChange={ValueUpdater}/>
            <label htmlFor="Government">Government</label>
          </div>

          <div>
            <input type="radio" id="Private" name="organizationType" value="Private" onChange={ValueUpdater}/>
            <label htmlFor="Private">Private</label>
          </div>

          <div>
            <input type="radio" id="Public" name="organizationType" value="Public" onChange={ValueUpdater}/>
            <label htmlFor="Public">Public</label>
          </div>

          <div>
            <input type="radio" id="Academia" name="organizationType" value="Academia" onChange={ValueUpdater}/>
            <label htmlFor="Academia">Academia</label>
          </div>

          <div>
            <input type="radio" id="Startup" name="organizationType" value="Startup" onChange={ValueUpdater}/>
            <label htmlFor="Startup">Startup</label>
          </div>

          <div>
            <input type="radio" id="Student" name="organizationType" value="Student" onChange={ValueUpdater}/>
            <label htmlFor="Student">Student</label>
          </div>

          <div>
            <input type="radio" id="Other" name="organizationType" value="Other" onChange={ValueUpdater}/>
            <label htmlFor="Other">Other</label>
          </div>
          </div>
        </div>



        <div className="radioDiv">
          <p>Participation Type *</p>

          <div>
          <div>
            <input type="radio" id="Invited Delegate" name="participationType" value="Invited Delegate" onChange={ValueUpdater}/>
            <label htmlFor="Invited Delegate">Invited Delegate</label>
          </div>

          <div>
            <input type="radio" id="Leading Sponsor" name="participationType" value="Leading Sponsor" onChange={ValueUpdater}/>
            <label htmlFor="Leading Sponsor">Leading Sponsor</label>
          </div>

          <div>
            <input type="radio" id="Partner" name="participationType" value="Partner" onChange={ValueUpdater}/>
            <label htmlFor="Partner">Partner</label>
          </div>
            
          <div>      
            <input type="radio" id="Applicant Delegate" name="participationType" value="Applicant Delegate" onChange={ValueUpdater}/>
            <label htmlFor="Applicant Delegate">Applicant Delegate</label>
          </div>

        </div>
        </div>
        </div>

        <div className="reason">
            <div>
            <p>Why Would You Like To Participate In {Home.eventName} Event? *</p>
            <textarea name="participationReason" id="participationReason" value={data.participationReason} rows="3.5" onChange={ValueUpdater}></textarea>
            </div>
            <div>
            <p>How Would You Like To Contribute Towards {Home.eventName}? *</p>
            <textarea name="contribute" id="contribute" rows="3.5"  value={data.contribute}  onChange={ValueUpdater}></textarea>
            </div>
        </div>

        <div className="imageInput">
          <div>
          <p>Please Attach Your Latest Photograph *</p>
          </div>
          <input type="file" id="image" accept="image/*" name="profileImg" onChange={(e)=>setData({...data,["profileImg"]:e.target.files[0]})}/>
        </div>

        <div className="socialBox">
          <div className="social">
            <label htmlFor="Facebook"><RiFacebookCircleLine/> Facebook</label>
            <input type="text" name="socialMedia_fb" value={data.socialMedia_fb} onChange={ValueUpdater}/>
          </div>
          <div className="social">
            <label htmlFor="LinkedIn"><RiLinkedinLine/> LinkedIn</label>
            <input type="text" name="socialMedia_ln" value={data.socialMedia_ln} onChange={ValueUpdater}/>
          </div>
          <div className="social">
            <label htmlFor="Instagram"><RiInstagramLine/> Instagram</label>
            <input type="text" name="socialMedia_in" value={data.socialMedia_in} onChange={ValueUpdater}/>
          </div>
          <div className="social">
            <label htmlFor="Twitter"><RiTwitterLine/> Twitter</label>
            <input type="text" name="socialMedia_tw" value={data.socialMedia_tw} onChange={ValueUpdater}/>
          </div>
        </div>

        <div className="radioDiv">
          <p>Select Registration Type: *</p>
          <div>
            {DelegateTicketCms.map((type,index) => (
            <div key={index}>
              <input
                type="radio"
                name="registrationType"
                value={type.DelegateTicketName}
                onChange={handleRadioChange}
              />
              <label htmlFor={type.DelegateTicketName}>{type.DelegateTicketName} (${type.DelegateTicketPrice})</label>
            </div>
            ))}

            <input type="text" placeholder="Amount" name="amount" onChange={ValueUpdater} value={data.amount}/>
            </div>
        </div>
        <div style={{textAlign:'left'}}>
          <button style={{backgroundColor: Color.secondaryBackgroundColor, color: Color.secondaryTextColor}} type="submit" className="btn" onClick={submit}>Submit</button>
        </div>
      </form>
      </div>
      <ToastContainer />
    </div>
  )
}

export default FormDelegate;