import './TicketComp.css'
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import GetHomeState from '../../Utils/GetHomeState'
import GetColorState from '../../Utils/GetColorState'


const TicketComp = () => {
  const { Home } = GetHomeState();
  const { Color } = GetColorState();

  return (
    <div className='flex_row_c ticketDiv'>
          <div className='ticketDiv_left'>
            <div className='ticketDot'>
              <svg width="321" height="111" viewBox="0 0 321 111" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M282.096 72.0708L0.131836 1.20129" stroke="black" strokeWidth="1.05407" strokeDasharray="5.27 5.27"/>
                <path d="M282.097 110.544C303.345 110.544 320.57 93.3192 320.57 72.0707C320.57 50.8223 303.345 33.597 282.097 33.597C260.848 33.597 243.623 50.8223 243.623 72.0707C243.623 93.3192 260.848 110.544 282.097 110.544Z" fill={`${Color.secondaryBackgroundColor}`}/>
              </svg>
            </div>
            
            <h2>Tickets</h2>
            <p>Tickets Are On Sale Now</p>
            <Link style={{backgroundColor:Color.secondaryBackgroundColor, color:Color.secondaryTextColor}} className='btn ticketBtn' to="/register-delegate">GET TICKETS</Link>
          </div>
          <div className='ticketDiv_right'>
            <img src={Home.companyLogo} alt='company logo'></img>
          </div>
    </div>
  )
}
export default TicketComp