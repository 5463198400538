// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headerDash{
    display: flex;
    justify-content: space-between;
    padding: 0rem 2rem;
    height: 5rem;
    align-items: center;
    overflow-x: hidden;
}
.header_leftDash{
    display: flex;
    flex-direction: row;
}

.header_rightDash{
    display: flex;
    gap: 1rem;
    align-items: center;
}
@media only screen and (max-width: 600px){
    .headerDash{
        overflow-x: hidden;
    }
    .header_leftDash{
        margin-left: 3rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/CmsComponents/Header/Header.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,kBAAkB;IAClB,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;AACvB;AACA;IACI;QACI,kBAAkB;IACtB;IACA;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".headerDash{\n    display: flex;\n    justify-content: space-between;\n    padding: 0rem 2rem;\n    height: 5rem;\n    align-items: center;\n    overflow-x: hidden;\n}\n.header_leftDash{\n    display: flex;\n    flex-direction: row;\n}\n\n.header_rightDash{\n    display: flex;\n    gap: 1rem;\n    align-items: center;\n}\n@media only screen and (max-width: 600px){\n    .headerDash{\n        overflow-x: hidden;\n    }\n    .header_leftDash{\n        margin-left: 3rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
