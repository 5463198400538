import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const initialState = {
    Color:'',
    message:'',
    isLoading:false,
    isError:false,
    isSuccess:false,
}


////////////////////////////Get All Color////////////////
export const GetAllColor = createAsyncThunk('Color/getCmsColor', async(_, thunkAPI)=>{
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/getCmsColor`
        const response = await axios.get(API_URL)
        return response.data
 
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})


export const UpdateAllColor = createAsyncThunk('Color/UpdateCmsColor', async({data,id}, thunkAPI)=>{

    try{
        const token = thunkAPI.getState().auth.user.token
        const config ={
           headers:{
               Authorization: `Bearer ${token}`
           }
       }
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/updateCmsColor/${id}`
        const response = await axios.patch(API_URL, data, config)
        return response.data
 
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})



export const ColorSlice = createSlice({
    name:'ColorSlice',
    initialState,
    reducers:{
        reset: (state)=> initialState,
        filterState:(state,action)=>{            
            state.Color = state.Color.filter((item) => item._id !== action.payload)
        }
    },
    extraReducers:(builder)=>{
        builder
        .addCase(GetAllColor.pending, (state, action)=>{
            state.isLoading = true
        })
        .addCase(GetAllColor.fulfilled, (state, action)=>{
            state.isLoading = false
            state.message = action.payload.message
            state.isSuccess = true
            if(action.payload.success===true){
                state.Color = action.payload.cmsColor
            }
        })
        .addCase(GetAllColor.rejected, (state, action)=>{
            state.isLoading = false
            state.isError=true
            state.message = action.payload
            state.Color={primaryBackgroundColor:"",secondaryBackgroundColor:"",primaryTextColor:"",secondaryTextColor:""}
        })

        .addCase(UpdateAllColor.pending, (state, action)=>{
            state.isLoading = true
        })
        .addCase(UpdateAllColor.fulfilled, (state, action)=>{
            state.isSuccess=true
            state.isLoading = false
            state.message = action.payload.message
               if(action.payload.success===true){
                state.Color = action.payload.updatedColor
                toast(state.message)
            }
        })
        .addCase(UpdateAllColor.rejected, (state, action)=>{
            state.isSuccess=false
            state.isLoading = false
            state.isError=true
            state.message = action.payload
            toast(action.payload)
            state.Color={primaryBackgroundColor:"",secondaryBackgroundColor:"",primaryTextColor:"",secondaryTextColor:""}
        })
    }
})


export const {reset,filterState} = ColorSlice.actions
export default ColorSlice.reducer