import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const initialState = {
    Home:'',
    message:'',
    isLoading:false,
    isError:false,
    isSuccess:false,
}


////////////////////////////Get All Home////////////////
export const GetAllHome = createAsyncThunk('Home/updateCmsHome', async(_, thunkAPI)=>{
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/getCmsHome`
        const response = await axios.get(API_URL)
        return response.data
 
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})


export const CreateSingleHome = createAsyncThunk('Home/CreateSingleHome', async(data, thunkAPI)=>{

    try{
        const token = thunkAPI.getState().auth.user.token
        const config ={
           headers:{
               Authorization: `Bearer ${token}`
           }
       }
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/createCmsHome`
        const response = await axios.post(API_URL, data, config)
        return response.data
 
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})


export const UpdateAllHome = createAsyncThunk('Home/UpdateAllHome', async({formData,id}, thunkAPI)=>{

    try{
        const token = thunkAPI.getState().auth.user.token
        const config ={
           headers:{
               Authorization: `Bearer ${token}`
           }
       }
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/updateCmsHome/${id}`
        const response = await axios.patch(API_URL, formData, config)
        return response.data
 
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})



export const HomeSlice = createSlice({
    name:'HomeSlice',
    initialState,
    reducers:{
        reset: (state)=> initialState,
        filterState:(state,action)=>{            
            state.Home = state.Home.filter((item) => item._id !== action.payload)
        }
    },
    extraReducers:(builder)=>{
        builder
        .addCase(GetAllHome.pending, (state, action)=>{
            state.isLoading = true
        })
        .addCase(GetAllHome.fulfilled, (state, action)=>{
            state.isLoading = false
            state.message = action.payload.message
            state.isSuccess = true
            if(action.payload.success===true){
                state.Home = action.payload.cmsHome
            }
            // toast("All Home Posts Loaded")
        })
        .addCase(GetAllHome.rejected, (state, action)=>{
            state.isLoading = false
            state.isError=true
            state.message = action.payload
            state.Home={_id:"",eventLogo:"",companyLogo:"",homeBanner:"",eventName:"",eventCollab:"",companyName:"",eventDate:"",eventCity:"",eventCountry:"",aboutHeading:"",aboutContent:"",previousHeading:"",previousContent:"",motiveHeading:"",motiveContent:"",venueAddress:"",backgroundColor:"",textColor:""}
            // toast("Error in Loading Home")
        })

        .addCase(CreateSingleHome.pending, (state, action)=>{
            state.isLoading = true
        })
        .addCase(CreateSingleHome.fulfilled, (state, action)=>{
            state.isLoading = false
            state.message = action.payload.message
            toast(action.payload.message)
            
        })
        .addCase(CreateSingleHome.rejected, (state, action)=>{
            state.isLoading = false
            state.isError=true
            state.message = action.payload
            toast(action.payload)

        })


        .addCase(UpdateAllHome.pending, (state, action)=>{
            state.isLoading = true
        })
        .addCase(UpdateAllHome.fulfilled, (state, action)=>{
            state.isSuccess=true
            state.isLoading = false
            state.message = action.payload.message
               if(action.payload.success===true){
                state.Home = action.payload.updatedHomePage
                toast(state.message)

            }
        })
        .addCase(UpdateAllHome.rejected, (state, action)=>{
            state.isSuccess=false
            state.isLoading = false
            state.isError=true
            state.message = action.payload
            toast(action.payload)
            state.Home={_id:"",eventLogo:"",companyLogo:"",homeBanner:"",eventName:"",eventCollab:"",eventDate:"",eventCity:"",eventCountry:"",aboutHeading:"",aboutContent:"",previousHeading:"",previousContent:"",motiveHeading:"",motiveContent:"",venueAddress:"",backgroundColor:"",textColor:""}
        })
    }
})


export const {reset,filterState} = HomeSlice.actions
export default HomeSlice.reducer