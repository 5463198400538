import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { CreateSinglePartner, GetAllPartners } from '../../features/PartnerCmsSlice/PartnerCmsSlice';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const CreatePartner = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [data, setData] = useState({
        partnerImg: null, // Initialize with null for the file input
    });

const handleChange = (e) => {
    // Handle file input differently
        setData({ ...data, [e.target.id]: e.target.files[0] });
};


const sendForm = (e) => {
    e.preventDefault();
    if ( data.partnerImg !== null) {
    const formData = new FormData();
    formData.append('partnerImg', data.partnerImg);

    dispatch(CreateSinglePartner(formData))////////it is returning a promise if promise is fulfilled then .then is executed
    .then(() => {
        // After creating the speaker, call GetAllPartners API to refresh the list
        dispatch(GetAllPartners());
        navigate('/dashboard/partner');
    })
    .catch((error) => {
        // Handle any errors that occur during speaker creation
        toast.error('Error creating partner: ' + error.message);
    });
} else {
        toast('Please Add Image');
    }
};


return (
    <form className="CreateCareer">
    <div className="form_left">
        <input id="partnerImg" type="file" accept="image/*" onChange={(e) => handleChange(e)}/>
    </div>
    <div style={{ textAlign: 'left' }}>
        <button className="btn" onClick={(e) => sendForm(e)}>Submit</button>
    </div>
    </form>
);
};

export default CreatePartner;
