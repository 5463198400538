import { useState } from 'react';
import { useEffect } from 'react';
import '../../Pages/Speakers/Speakers.css'
import Hero from "../../Components/Hero/Hero";
import BannerLine from '../../Components/bannerLine/bannerLine';
import AboutUs from '../../Components/AboutUs/AboutUs';
import PreviousCT from '../../Components/PreviousCT/PreviousCT';
import Agenda from '../../Components/Agenda/Agenda';
import VenueComp from '../../Components/VenueComp/VenueComp'
import TicketComp from '../../Components/TicketComp/TicketComp'
import SpeakerList from '../../Components/SpeakerList/SpeakerList'
import PrevCTDot2 from '../../Components/PrevCTDot2/PrevCTDot2';
import PrevCTDot1 from '../../Components/PrevCTDot1/PrevCTDot1';
import AgendaDot1 from '../../Components/AgendaDot1/AgendaDot1';
import Sponsorship from '../../Components/Sponsorship/Sponsorship';
import SubscribePopup from '../../Components/SubscribePopup/SubscribePopup';
import Partners from '../../Components/Partners/Partners';



const Home = () => {
  const [isSubscribePopupOpen, setIsSubscribePopupOpen] = useState(false);

  useEffect(() => {
  const popupTimer = setTimeout(() => {
    setIsSubscribePopupOpen(true);
  }, 6000);

  return () => {
    clearTimeout(popupTimer);
  };
}, []);

const handleCloseSubscribePopup = () => {
  setIsSubscribePopupOpen(false);
};


  return (
    <div>
      <Hero/>
      <BannerLine/>
      <AboutUs/>
      <SpeakerList/>
      {isSubscribePopupOpen && <SubscribePopup onClose={handleCloseSubscribePopup} />}
      <PrevCTDot1/>
      <PreviousCT/>
      <PrevCTDot2/>
      <AgendaDot1/>
      <Agenda/>
      <Sponsorship/>
      <VenueComp/>
      <Partners/>
      <TicketComp/>
    </div>
  )
}
export default Home