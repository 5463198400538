import React, { useEffect } from 'react';
import { useSelector , useDispatch } from 'react-redux';
import {GetAllDelegates , filterState} from '../../features/DelegateSlice/DelegateSlice'
import DataTable from '../../CmsComponents/DataTable/DataTable';
import {RiDeleteBin7Line} from 'react-icons/ri'
import {BiEditAlt} from 'react-icons/bi'
import { Link } from 'react-router-dom';


const DelegatePage = () => {
  const dispatch = useDispatch()

  const DelegateColumns = [
    { field: 'fullName', headerName: 'Full Name', width: 150,},
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'contact', headerName: 'Contact', width: 150 },
    { field: 'paymentStatus', headerName: 'Payment Status', width: 150},
    { field: 'amount', headerName: 'Amount', width: 150},
    { field: 'registrationType', headerName: 'Registration Type', width: 150},
    { field: 'createdAt', headerName: 'CreatedAt', width: 200},  
  ];

  const { delegates } = useSelector((store) => store.delegates);
  useEffect(()=>{
    dispatch(GetAllDelegates())
    
  },[dispatch])
  
  return (
    <div>
      {/* <h5 style={{padding:'2rem'}}>All Delegates</h5> */}
      <DataTable columns={DelegateColumns} data={delegates}/>
    </div>
  )
}

export default DelegatePage