import "./Partners.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { GetAllPartners } from '../../features/PartnerCmsSlice/PartnerCmsSlice';
import GetColorState from '../../Utils/GetColorState'


const Partners = () => {
  const dispatch = useDispatch()
  const { Color } = GetColorState();

  const {Partners} = useSelector((store)=>store.partnerCms)

  useEffect(() => {
    dispatch(GetAllPartners());
  }, [dispatch]);

  return (
    <div style={{backgroundColor: Color.primaryBackgroundColor, color: Color.primaryTextColor}} className="partner-container">
      <h4>Event Partners</h4>
      {Partners.length > 0 ? (
      <div className="partners-card-parent">
        {Partners.map((partner,index)=>{
          return(
            <div key={index} className="partner-img">
              <img src={partner.partnerImg} alt="partner"/>
            </div>
          )
        })}
      </div>
          ):<h5 style={{textAlign:"center"}}>TBH Soon</h5>}
    </div>
  )
}
export default Partners