import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GetAllSponsorTicketCms } from '../features/SponsorTicketCmsSlice/SponsorTicketCmsSlice'

function GetSponsorTicketState() {
    const { SponsorTicketCms, isSuccess } = useSelector((store) => store.sponsorTicketCms);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GetAllSponsorTicketCms());
    }, [isSuccess, dispatch]);
return {SponsorTicketCms, isSuccess };
}
export default GetSponsorTicketState;