import React, { useEffect, useState } from 'react';
// import './SpeakerCmsPage.css';
import TextField from '@mui/material/TextField';
import { CreateSingleSpeaker, GetAllSpeakers } from '../../features/SpeakerCmsSlice/SpeakerCmsSlice';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const CreateSpeaker = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [data, setData] = useState({
        name: '',
        designation: '',
        company: '',
        linkedIn: '',
        speakerImgDash: null, // Initialize with null for the file input
});

const handleChange = (e) => {
    if (e.target.id === 'speakerImgDash') {
    // Handle file input differently
        setData({ ...data, [e.target.id]: e.target.files[0] });
    } else {
        setData({ ...data, [e.target.id]: e.target.value });
    }
};


const sendForm = (e) => {
    e.preventDefault();
    if ( data.name !== '' && data.designation !== '' && data.company !== '' && data.linkedIn !== '' && data.speakerImgDash !== null) {
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('designation', data.designation);
    formData.append('company', data.company);
    formData.append('linkedIn', data.linkedIn);
    formData.append('speakerImgDash', data.speakerImgDash);

    dispatch(CreateSingleSpeaker(formData))
    .then(() => {
        // After creating the speaker, call GetAllSpeakers API to refresh the list
        dispatch(GetAllSpeakers());
        navigate('/dashboard/speaker');
    })
    .catch((error) => {
        // Handle any errors that occur during speaker creation
        toast.error('Error creating speaker: ' + error.message);
    });
} else {
        toast('Please fill out the form completely.');
    }
};


return (
    <form className="CreateCareer">
    <div className="form_left">
        <TextField id="name" label="Name" variant="outlined" onChange={(e) => handleChange(e)} />
        <TextField id="designation" label="Designation" variant="outlined" onChange={(e) => handleChange(e)}/>
        <TextField id="company" label="Company" variant="outlined" onChange={(e) => handleChange(e)} />
        <TextField id="linkedIn" label="LinkedIn" variant="outlined" onChange={(e) => handleChange(e)} />
        <input id="speakerImgDash" type="file" accept="image/*" onChange={(e) => handleChange(e)}/>
    </div>
    <div style={{ textAlign: 'left' }}>
        <button className="btn" onClick={(e) => sendForm(e)}>Submit</button>
    </div>
    </form>
);
};

export default CreateSpeaker;
