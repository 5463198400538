import { configureStore } from '@reduxjs/toolkit';
import InvoiceSlice from '../features/InvoiceSlice/InvoiceSlice';
import RegisterSlice from '../features/Register/Register'
import authSliceReducer from '../features/AuthSlice/AuthSlice';
import delegateReducer from '../features/DelegateSlice/DelegateSlice'
import sponsorReducer from '../features/SponsorSlice/SponsorSlice'
import speakerCmsReducer from '../features/SpeakerCmsSlice/SpeakerCmsSlice'
import prevYearspeakerCmsReducer from '../features/PrevYearSpeakerCmsSlice/PrevYearSpeakerCmsSlice'
import partnerCmsReducer from '../features/PartnerCmsSlice/PartnerCmsSlice'
import homeCmsReducer from '../features/HomeCmsSlice/HomeCmsSlice'
import eventCmsReducer from '../features/EventCmsSlice/EventCmsSlice'
import aboutCmsReducer from '../features/AboutCmsSlice/AboutCmsSlice'
import footerCmsReducer from '../features/FooterCmsSlice/FooterCmsSlice'
import delegateTicketCmsReducer from '../features/DelegateTicketCmsSlice/DelegateTicketCmsSlice'
import sponsorTicketCmsReducer from '../features/SponsorTicketCmsSlice/SponsorTicketCmsSlice'
import contactFormReducer from '../features/ContactFormSlice/ContactFormSlice';
import colorReducer from '../features/ColorCmsSlice/ColorCmsSlice';



export const store = configureStore({
    reducer:{
        invoice: InvoiceSlice,
        register: RegisterSlice,
        delegates: delegateReducer,
        sponsors: sponsorReducer,
        auth: authSliceReducer,
        speakerCms: speakerCmsReducer,
        prevYearspeakerCms: prevYearspeakerCmsReducer,
        partnerCms: partnerCmsReducer,
        homeCms: homeCmsReducer,
        eventCms: eventCmsReducer,
        aboutCms: aboutCmsReducer,
        footerCms: footerCmsReducer,
        delegateTicketCms: delegateTicketCmsReducer,
        sponsorTicketCms: sponsorTicketCmsReducer,
        contactFormCms:contactFormReducer,
        colorCms:colorReducer

    }
});
