import {createSlice,createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";

const initialState ={ 
    invoice:{
        email:"",
        fullName:"",
        amount:"",
        registrationType:"",
        organizationName:"",
        contact:"",
        id:"",
        mailHandle:"",
        eventName:"",
        eventCity:"",
        companyLogo:"",
        eventCountry:""
    },
    isSuccess : false,
    isLoading: false,
    isError:false,
    message:""
}


export const getDelegateInvoice  = createAsyncThunk("invoice/getDelegateInvoice", async(token, thunkAPI)=>{

    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/getDelegateInvoice/${token}`

        const response = await axios.get(API_URL)
        const data =  await response.data
        return data

    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }

})


export const getSponsorInvoice  = createAsyncThunk("invoice/getSponsorInvoice", async(token, thunkAPI)=>{

    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/getSponsorInvoice/${token}`

        const response = await axios.get(API_URL)
        const data =  await response.data
        return data

    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }

})






export const InvoiceSlice =  createSlice({ 
    name:"invoice",
    initialState,
    reducers:{
        reset:(state)=>{
            state.invoice={ email:"", fullName:"", amount:"", participationType:"" ,organizationName:"",contact:"", id:"",mailHandle:"",eventName:"",eventCity:"",companyLogo:"",eventCountry:""};
            state.isSuccess=false;
            state.isLoading= false;
            state.isError=false;
    }
    },
    extraReducers:(builder)=>{
        builder
        .addCase(getDelegateInvoice.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(getDelegateInvoice.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.isSuccess =  true
            if(action.payload.success === true){
            state.invoice = action.payload.invoice;
            }
            state.message = action.payload.message;

        })
        .addCase(getDelegateInvoice.rejected, (state, action)=>{
            state.isError=true;
            state.isLoading = false;
            state.message = action.payload.message;
        })

        .addCase(getSponsorInvoice.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(getSponsorInvoice.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.isSuccess =  true
            if(action.payload.success === true){
            state.invoice = action.payload.invoice;
            }
            state.message = action.payload.message;

        })
        .addCase(getSponsorInvoice.rejected, (state, action)=>{
            state.isError=true;
            state.isLoading = false;
            state.message = action.payload.message;
        })

    }
})


export const {reset} = InvoiceSlice.actions
export default InvoiceSlice.reducer
