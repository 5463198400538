import React, { useEffect } from 'react';
import { useSelector , useDispatch } from 'react-redux';
import {GetAllPrevYearSpeakers , filterState,DeletePrevYearSingleSpeaker} from '../../features/PrevYearSpeakerCmsSlice/PrevYearSpeakerCmsSlice'
import DataTable from '../../CmsComponents/DataTableSpeaker/DataTableSpeaker';
import {RiDeleteBin7Line} from 'react-icons/ri'
import {BiEditAlt} from 'react-icons/bi'
import { Link } from 'react-router-dom';


const SpeakerPage = () => {
  const dispatch = useDispatch()

  const deleteSpeaker=async(id)=>{
    dispatch(DeletePrevYearSingleSpeaker(id));
    dispatch(filterState(id))
    
  }
  const SpeakerColumns = [
    {
      field: 'id', 
      headerName: 'Sr.No',
      width: 70,
    },
    { field: 'name', headerName: 'Name', width: 130 },
    {
        field: 'speakerImgDash',
        headerName: 'Profile',
        width: 900,
        renderCell: (params) => (
          <img
            src={params.value} // Assuming 'speakerImgDash' contains the image URL
            alt={params.row.name} // You can set the alt text to the speaker's name or any other meaningful description
            style={{ width: '100px', height: 'auto' }}
          />
        ),
      },
        {field: "Action",headerName: "Action",width: 120, renderCell: (params) => 
    <div style={{display:"flex", alignItems:"center", gap:"1rem"}}>
      <button style={{fontSize:"1.5rem", color:"#3166BB", cursor:"pointer", background:'none', border:'none'}} onClick={()=>deleteSpeaker(params.row._id)}><RiDeleteBin7Line/></button>
    </div>},
  
  ];
  const {PrevYearSpeakers} = useSelector((store)=>store.prevYearspeakerCms)
  useEffect(()=>{
    dispatch(GetAllPrevYearSpeakers())
    
  },[dispatch])
  



  return (
    <div>
    {PrevYearSpeakers ? (
      <DataTable columns={SpeakerColumns} data={PrevYearSpeakers} link="/dashboard/create-prevYearSpeaker" />
    ) : (
      <DataTable columns={SpeakerColumns} data={[]} link="/dashboard/create-prevYearSpeaker" />
    )}

    </div>
  )
}

export default SpeakerPage