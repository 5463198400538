import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { CreatePrevYearSingleSpeaker, GetAllPrevYearSpeakers } from '../../features/PrevYearSpeakerCmsSlice/PrevYearSpeakerCmsSlice';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const CreateSpeaker = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [data, setData] = useState({
        name: '',
        speakerImgDash: null,
});

const handleChange = (e) => {
    if (e.target.id === 'speakerImgDash') {
    // Handle file input differently
        setData({ ...data, [e.target.id]: e.target.files[0] });
    } else {
        setData({ ...data, [e.target.id]: e.target.value });
    }
};


const sendForm = (e) => {
    e.preventDefault();
    if ( data.name !== '' && data.speakerImgDash !== null) {
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('speakerImgDash', data.speakerImgDash);

    dispatch(CreatePrevYearSingleSpeaker(formData))
    .then(() => {
        // After creating the speaker, call GetAllPrevYearSpeakers API to refresh the list
        dispatch(GetAllPrevYearSpeakers());
        navigate('/dashboard/prevYearSpeaker');
    })
    .catch((error) => {
        // Handle any errors that occur during speaker creation
        toast.error('Error creating speaker: ' + error.message);
    });
} else {
        toast('Please fill out the form completely.');
    }
};


return (
    <form className="CreateCareer">
    <div className="form_left">
        <TextField id="name" label="Name" variant="outlined" onChange={(e) => handleChange(e)} />
        <input id="speakerImgDash" type="file" accept="image/*" onChange={(e) => handleChange(e)}/>
    </div>
    <div style={{ textAlign: 'left' }}>
        <button className="btn" onClick={(e) => sendForm(e)}>Submit</button>
    </div>
    </form>
);
};

export default CreateSpeaker;
