import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const initialState = {
    SponsorTicketCms:[],
    message:'',
    isLoading:false,
    isError:false,
    isSuccess:false,
}



////////////////////////////Get All SponsorTicketCms////////////////
export const GetAllSponsorTicketCms = createAsyncThunk('SponsorTicket/GetAllSponsorTicketCms', async(_, thunkAPI)=>{
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/getAllCmsSponsorTicket`
        const response = await axios.get(API_URL)
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})


export const CreateSponsorTicketCms = createAsyncThunk('SponsorTicket/CreateSponsorTicketCms', async(data, thunkAPI)=>{
    try{
        const token = thunkAPI.getState().auth.user.token
        const config ={
            headers:{
                Authorization: `Bearer ${token}`
            }
        }
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/createCmsSponsorTicket`
        const response = await axios.post(API_URL, data, config)
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})


export const DeleteSingleSponsorTicketCms = createAsyncThunk('SponsorTicket/DeleteSingleSponsorTicketCms', async(id, thunkAPI)=>{
    try{
        const token = thunkAPI.getState().auth.user.token
        const config ={
            headers:{
                Authorization: `Bearer ${token}`
            }
        }
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/deleteSingleCmsSponsorTicket/${id}`
        const response = await axios.delete(API_URL, config)
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})


export const SponsorTicketCmslice = createSlice({
    name:'SponsorTicketCmslice',
    initialState,
    reducers:{
        reset: (state)=> initialState,
        filterState:(state,action)=>{            
            state.SponsorTicketCms = state.SponsorTicketCms.filter((item) => item._id !== action.payload)
        }
    },
    extraReducers:(builder)=>{
        builder
        .addCase(GetAllSponsorTicketCms.pending, (state, action)=>{
            state.isLoading = true
        })
        .addCase(GetAllSponsorTicketCms.fulfilled, (state, action)=>{
            state.isLoading = false
            state.message = action.payload.message
            if(action.payload.success===true){
                const RequiredData = action.payload.AllCmsSponsorTicket.map((listing,index) => ({
                    ...listing,
                    id: index+1,
                }));
            state.SponsorTicketCms = RequiredData
            }
        })
        .addCase(GetAllSponsorTicketCms.rejected, (state, action)=>{
            state.isLoading = false
            state.isError=true
            state.message = action.payload
            state.SponsorTicketCms={_id:"",SponsorTicketName:"",SponsorTicketPrice:"",perks:""}
        })

        .addCase(CreateSponsorTicketCms.pending, (state, action)=>{
            state.isLoading = true
        })
        .addCase(CreateSponsorTicketCms.fulfilled, (state, action)=>{
            state.isLoading = false
            state.message = action.payload.message
            toast(action.payload.message)
        })
        .addCase(CreateSponsorTicketCms.rejected, (state, action)=>{
            state.isLoading = false
            state.isError=true
            state.message = action.payload
            toast(action.payload)
        })

        .addCase(DeleteSingleSponsorTicketCms.pending, (state, action)=>{
            state.isLoading = true
        })
        .addCase(DeleteSingleSponsorTicketCms.fulfilled, (state, action)=>{
            state.isLoading = false
            state.message = action.payload.message
            toast(action.payload.message)
        })
        .addCase(DeleteSingleSponsorTicketCms.rejected, (state, action)=>{
            state.isLoading = false
            state.isError=true
            state.message = action.payload
            toast(action.payload)
        })
    }
})


export const {reset,filterState} = SponsorTicketCmslice.actions
export default SponsorTicketCmslice.reducer