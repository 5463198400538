import React, { useEffect,useState } from 'react'
import { useSelector , useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import {toast} from 'react-toastify'
// import '../CareerPage/CareerPage.css'
import { useLocation, useNavigate } from 'react-router-dom';
import {getSingleSpeaker,UpdateSingleSpeaker,reset, GetAllSpeakers} from '../../features/SpeakerCmsSlice/SpeakerCmsSlice'



const UpdateSpeaker = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location  = useLocation();
    const {SingleSpeaker,isSuccess} = useSelector((store)=>store.speakerCms)
    const id = location.pathname.substring(location.pathname.lastIndexOf('/')+1)
    const [data, setData] = useState({
        name: '',
        designation: '',
        company: '',
        linkedIn: '',
        speakerImgDash: null,
    });

const handleChange =(e)=>{
    if (e.target.id === 'speakerImgDash') {
        // Handle file input differently
            setData({ ...data, [e.target.id]: e.target.files[0] });
        } else {
            setData({ ...data, [e.target.id]: e.target.value });
        }
    };

const sendForm = (e)=>{
    e.preventDefault()
    if ( data.name !== '' && data.designation !== '' && data.company !== '' && data.linkedIn !== '' && data.speakerImgDash !== null) {
        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('designation', data.designation);
        formData.append('company', data.company);
        formData.append('linkedIn', data.linkedIn);
        formData.append('speakerImgDash', data.speakerImgDash);

        dispatch(UpdateSingleSpeaker({formData,id}))
        .then(() => {
            navigate('/dashboard/speaker');
        })
        .catch((error) => {
            toast.error('Error updating speaker: ' + error.message);
        });    }else{
        toast('Please fill out the form completely.');
    }
}

useEffect(()=>{
    dispatch(getSingleSpeaker(id))
    if(isSuccess){
        setData({ name:SingleSpeaker.name, designation:SingleSpeaker.designation, company:SingleSpeaker.company, linkedIn:SingleSpeaker.linkedIn, speakerImgDash:null })
    }
},[isSuccess])



return (
    <form className='CreateCareer'> 
    <div className="form_left">
        <TextField id="name" value={data.name} label="Name" variant="outlined" onChange={(e)=>{handleChange(e)}}/>
        <TextField id="designation" value={data.designation} label="Designation" variant="outlined" onChange={(e)=>{handleChange(e)}}/>
        <TextField id="company" value={data.company} label="Company" variant="outlined" onChange={(e)=>{handleChange(e)}}/>
        <TextField id="linkedIn" value={data.linkedIn} label="LinkedIn" variant="outlined" onChange={(e)=>{handleChange(e)}}/>
        <input id="speakerImgDash" type="file" accept="image/*" onChange={(e) => handleChange(e)}/>
    </div>
    <div style={{ textAlign: 'left' }}>
        <button className='btn' onClick={(e)=>sendForm(e)}>Submit</button>
    </div>
</form>
)
}

export default UpdateSpeaker