import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
// import './DataTable.css'
export default function DataTable({data, columns, link}) {
  
    return (
      <div style={{ height: "100%", width: '100%' ,padding:"2rem 2rem"}}>
        <div className='create_btn'>
        <Link to={link} className='btn'>New Sponsor Ticket +</Link>

        </div>
        <DataGrid
          rows={data}
          columns={columns}
          rowHeight={100} // Specify the desired row height in pixels
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10]}
          // checkboxSelection
        />
      </div>
    );
  }