import "./Header.css";
import { Link } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import GetHomeState from '../../Utils/GetHomeState'
import GetColorState from '../../Utils/GetColorState'


const Header = () => {
  const { Home } = GetHomeState();
  const { Color } = GetColorState();

return (
<div style={{backgroundColor: Color.primaryBackgroundColor, color: Color.primaryTextColor}} className='Bannerheader'>
    <div style={{color: Color.primaryTextColor}} className="header_left">
      <img src={Home.eventLogo} alt="event logo"/>
    </div>
    <div style={{color: Color.primaryTextColor}} className="header_right animate-element">
      <Link to="/">HOME</Link>
      <Link to="/about">ABOUT</Link>
      <Link to="/event">EVENT</Link>
      <Link to="/speakers">SPEAKERS</Link>
      <Link to="/contact">CONTACT</Link>
      <Link to="/register-sponsor">SPONSOR</Link>
    </div>
    <Link style={{backgroundColor: Color.secondaryBackgroundColor, color: Color.secondaryTextColor}} className="btn animate-element smallPadding" to="/register-delegate">REGISTER</Link>
</div>
    
  )
}

export default Header;