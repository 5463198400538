import './Footer.css'
import {Link} from 'react-router-dom'
import {BsInstagram,BsLinkedin} from "react-icons/bs";
import {MdMail} from "react-icons/md";
import GetFooterState from '../../Utils/GetFooterState'
import GetHomeState from '../../Utils/GetHomeState'
const currentDate = new Date();
const currentYear = currentDate.getFullYear();
import GetColorState from '../../Utils/GetColorState'



const Footer = () => {
    const { Footer } = GetFooterState();
    const { Home } = GetHomeState();
    const { Color } = GetColorState();


    return (
    <div style={{color: Color.primaryTextColor}} className='footer'>
        <div style={{backgroundColor: Color.primaryBackgroundColor}} className='flex_row_sb footer_top'>
            <div className='flex_row_sb'>
                <div className='flex_row_c footer_top_left'>
                    <a className="icons-a" href={Footer.instaHandle}><BsInstagram className="icons-circle"/></a>
                    <a className="icons-a" href={Footer.linkedinHandle}><BsLinkedin className="icons-circle"/></a>
                    <a className="icons-a" href={`mailto:${Footer.mailHandle}`}><MdMail className="icons-circle" /></a>
                </div>
                <div className='flex_row_sb footer_top_right'>
                    <Link to="/policy" className='linkFooter'>Privacy Policy</Link>
                </div>
            </div>
            <div className='linkFooter'><small className='linkFooter'>{currentYear} © Copyrights {Home.companyName}. All Rights Reserved</small></div>
        </div>
    </div>
  )
}

export default Footer