import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GetAllColor } from '../features/ColorCmsSlice/ColorCmsSlice'

function GetColorState() {
    const { Color, isSuccess } = useSelector((store) => store.colorCms);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GetAllColor());
    }, [isSuccess, dispatch]);
return { Color, isSuccess };
}
export default GetColorState;