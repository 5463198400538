import './Login.css';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { FaSignInAlt } from 'react-icons/fa';
import { login, reset } from '../../features/AuthSlice/AuthSlice';
import Spinner from '../../CmsComponents/Spinner/Spinner';
import GetColorState from '../../Utils/GetColorState'


const Login = () => {
    const { Color } = GetColorState();

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const { email, password } = formData;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user, isSuccess, isLoading, isError, message } = useSelector((store) => store.auth);

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }
    if (isSuccess || user) {
      navigate('/dashboard');
    }
    dispatch(reset());
  }, [user, isSuccess, isLoading, isError, message, navigate, dispatch]);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const userData = { email, password };
    dispatch(login(userData));
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>

    <div className='login-container'>
        <div className='flex_row_sb' style={{width:'100%', padding:'2rem',backgroundColor: Color.primaryBackgroundColor, color: Color.primaryTextColor}}>
           <div>
            <Link style={{fontSize:'large'}} to="/">HOME</Link>
           </div>
           <div>
             <Link style={{fontSize:'large'}} to="/dashboard">Dashboard</Link>
           </div>
        </div>
        <div className='flex_row_c' style={{height:'100%',padding:'1rem'}}>           
          <div className='login-form-container'>
        <h5 style={{marginBottom:'1rem'}}>Welcome Back</h5>
        <form onSubmit={onSubmit}>
          <div className='form-group'>
            <input
              type='email'
              id='email'
              name='email'
              value={email}
              placeholder='Email'
              onChange={onChange}
            />
          </div>
          <div className='form-group'>
            <input
              type='password'
              id='password'
              name='password'
              value={password}
              placeholder='Password'
              onChange={onChange}
            />
          </div>
          <div className='form-group'>
            <button className='btn' style={{backgroundColor: Color.primaryBackgroundColor, color: Color.primaryTextColor}} type='submit'>
               Login
            </button>
          </div>
        </form>
      </div>
      </div>
    </div>
    </>
  );
};

export default Login;
