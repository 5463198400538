import {BrowserRouter as Router,Routes,Route,Navigate} from "react-router-dom";
import React, {Suspense}from 'react';
import { useSelector } from 'react-redux'
import './App.css';
import Home from "./Pages/Home/Home"
import Footer from "./Components/Footer/Footer";
import HeaderSimple from "./Components/HeaderSimple/HeaderSimple";
import MobileNav from './Components/MobileNav/MobileNav'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
import ScrollProgress from "./Components/ScrollProgress/ScrollProgress"
import FormDelegate from "./Pages/FormDelegate/FormDelegate"
import FormSponsor from "./Pages/FormSponsor/FormSponsor"
import Main from './CmsPages/Main/Main'
import Login from './CmsPages/Login/Login'
// import Register from './CmsPages/Register/Register'
import DelegatePage from './CmsPages/DelegatePage/DelegatePage'
import SponsorPage from './CmsPages/SponsorPage/SponsorPage'
import SpeakerCmsPage from "./CmsPages/SpeakerCmsPage/SpeakerCmsPage";
import CreateCmsSpeaker from "./CmsPages/SpeakerCmsPage/CreateCmsSpeaker";
import UpdateCmsSpeaker from "./CmsPages/SpeakerCmsPage/UpdateCmsSpeaker";
import PrevYearSpeakerCmsPage from './CmsPages/PrevYearSpeakerCmsPage/PrevYearSpeakerCmsPage'
import CreateCmsprevYearSpeaker from './CmsPages/PrevYearSpeakerCmsPage/CreateCmsPrevYearSpeaker'
import PartnerCmsPage from "./CmsPages/PartnerCmsPage/PartnerCmsPage";
import CreateCmsPartner from "./CmsPages/PartnerCmsPage/CreateCmsPartner";
import HomeCmsPage from "./CmsPages/HomeCmsPage/HomeCmsPage"
import UpdateCmsHome from "./CmsPages/HomeCmsPage/UpdateCmsHome"
import AboutCmsPage from "./CmsPages/AboutCmsPage/AboutCmsPage"
import UpdateCmsAbout from "./CmsPages/AboutCmsPage/UpdateCmsAbout"
import FooterCmsPage from "./CmsPages/FooterCmsPage/FooterCmsPage"
import UpdateCmsFooter from "./CmsPages/FooterCmsPage/UpdateCmsFooter"
import DelegateTicketCmsPage from "./CmsPages/DelegateTicketCmsPage/DelegateTicketCmsPage";
import CreateCmsDelegateTicket from "./CmsPages/DelegateTicketCmsPage/CreateCmsDelegateTicket";
import SponsorTicketCmsPage from "./CmsPages/SponsorTicketCmsPage/SponsorTicketCmsPage";
import CreateCmsSponsorTicket from "./CmsPages/SponsorTicketCmsPage/CreateCmsSponsorTicket";
import EventCmsPage from "./CmsPages/EventCmsPage/EventCmsPage"
import UpdateCmsEvent from "./CmsPages/EventCmsPage/UpdateCmsEvent"
import ColorCmsPage from "./CmsPages/ColorCmsPage/ColorCmsPage"
import UpdateCmsColor from "./CmsPages/ColorCmsPage/UpdateCmsColor"
////lazy loading pages//////
const Speakers = React.lazy(()=> import('./Pages/Speakers/Speakers'))
const About = React.lazy(()=> import('./Pages/About/About'))
const Event = React.lazy(()=> import('./Pages/Event/Event'))
const ContactPage = React.lazy(()=> import('./Pages/ContactPage/ContactPage'))
const Policy = React.lazy(()=> import('./Pages/Policy/Policy'))
const Invoice = React.lazy(()=> import('./Pages/Invoice/Invoice'))








function App() {
  const { user } = useSelector((store) => store.auth);
  const isDashboardRoute = window.location.pathname.startsWith('/dashboard') || window.location.pathname.startsWith('/login');

  return (
    <div className="App">

      <Router>
        <HeaderSimple/>
        <MobileNav />
        <ScrollToTop/>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/speakers" element={<Suspense fallback={<div></div>}><Speakers/></Suspense>}/>
          <Route path="/about" element={<Suspense fallback={<div></div>}><About/></Suspense>}/>
          <Route path="/event" element={<Suspense fallback={<div></div>}><Event/></Suspense>}/>
          <Route path="/contact" element={<Suspense fallback={<div></div>}><ContactPage/></Suspense>}/>
          <Route path="/policy" element={<Suspense fallback={<div></div>}><Policy/></Suspense>}/>
          <Route path="/invoice" element={<Suspense fallback={<div></div>}><Invoice/></Suspense>}/>
          <Route path="/register-delegate" element={<FormDelegate/>}/>
          <Route path="/register-sponsor" element={<FormSponsor/>}/>
          <Route path="/login" element={<Login />} />
          {/* <Route path="/register" element={<Register />} /> */}

          {!user ? (
          <Route path="/dashboard" element={<Navigate to="/login" />} />
          ) : (
          <Route path="/dashboard" element={<Main />}>
            <Route index element={<HomeCmsPage/>} />
            <Route path="delegate" element={<DelegatePage />} />
            <Route path="sponsor" element={<SponsorPage />} />
            <Route path="speaker" element={<SpeakerCmsPage />} />
            <Route path="create-speaker" element={<CreateCmsSpeaker />} />
            <Route path="updateSpeaker/:id" element={<UpdateCmsSpeaker />} />
            <Route path="prevYearSpeaker" element={<PrevYearSpeakerCmsPage />} />
            <Route path="create-prevYearSpeaker" element={<CreateCmsprevYearSpeaker />} />
            <Route path="partner" element={<PartnerCmsPage />} />
            <Route path="create-partner" element={<CreateCmsPartner />} />
            <Route path="home" element={<HomeCmsPage />} />
            <Route path="updateHome/:id" element={<UpdateCmsHome />} />
            <Route path="about" element={<AboutCmsPage />} />
            <Route path="updateabout/:id" element={<UpdateCmsAbout />} />
            <Route path="footer" element={<FooterCmsPage />} />
            <Route path="updatefooter/:id" element={<UpdateCmsFooter />} />
            <Route path="delegateticket" element={<DelegateTicketCmsPage />} />
            <Route path="create-delegateticket" element={<CreateCmsDelegateTicket />} />
            <Route path="sponsorticket" element={<SponsorTicketCmsPage />} />
            <Route path="create-sponsorticket" element={<CreateCmsSponsorTicket />} />
            <Route path="event" element={<EventCmsPage />} />
            <Route path="updateEvent/:id" element={<UpdateCmsEvent />} />
            <Route path="color" element={<ColorCmsPage />} />
            <Route path="updatecolor/:id" element={<UpdateCmsColor />} />
          </Route>
          )}

        </Routes>
        {!isDashboardRoute && <Footer />}
      </Router>
      <ScrollProgress bgColor="#ffffff" gradientColor="#D22F7D"/>
      <ToastContainer />
    </div>
  );
}

export default App;