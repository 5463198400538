import React, { useEffect,useState } from 'react'
import { useSelector , useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import {toast} from 'react-toastify'
import { useLocation, useNavigate } from 'react-router-dom';
import {UpdateAllFooter,reset, GetAllFooter} from '../../features/FooterCmsSlice/FooterCmsSlice'



const UpdateFooter = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location  = useLocation();
    const id = location.pathname.substring(location.pathname.lastIndexOf('/')+1)
    const [data, setData] = useState({
        instaHandle: '',
        linkedinHandle: '',
        mailHandle: '',
        privacyPolicy:'',
        consent: '',
        infoWeCollect: '',
        infoUse: '',
        CCPA: '',
        GDPR: '',
        childrenInfo: '',
        changesToPrivacyPolicy: '',
    });

const handleChange =(e)=>{
        setData({ ...data, [e.target.id]: e.target.value });
    }

const sendForm = (e)=>{
    e.preventDefault()
    if (data.instaHandle !== '' && data.linkedinHandle !== '' && data.mailHandle !== '' && data.privacyPolicy !== '' && data.consent !== '' && data.infoWeCollect !== '' && data.infoUse !== '' && data.CCPA !== '' && data.GDPR !== '' && data.childrenInfo !== '' && data.changesToPrivacyPolicy !== '') {
        dispatch(UpdateAllFooter({data,id}))
        navigate('/dashboard/footer');
      } else {
        toast('Please fill out the form completely.');
      }
    }
let {Footer, isSuccess} = useSelector((store)=>store.footerCms)
Footer = Footer ? Footer : null;

useEffect(()=>{
    dispatch(GetAllFooter())
    if(isSuccess && Footer){
        setData({ instaHandle:Footer.instaHandle, linkedinHandle:Footer.linkedinHandle, mailHandle:Footer.mailHandle, privacyPolicy:Footer.privacyPolicy, consent:Footer.consent, infoWeCollect:Footer.infoWeCollect, infoUse:Footer.infoUse, CCPA:Footer.CCPA, GDPR:Footer.GDPR, childrenInfo:Footer.childrenInfo, changesToPrivacyPolicy:Footer.changesToPrivacyPolicy })
    }
},[isSuccess])

return (
    <form className='CreateCareer'> 
    <div className="form_left">
        <TextField id="instaHandle" value={data.instaHandle} label="Instagram" variant="outlined" onChange={(e)=>{handleChange(e)}}/>
        <TextField id="linkedinHandle" value={data.linkedinHandle} label="LinkedIn" variant="outlined" onChange={(e)=>{handleChange(e)}}/>
        <TextField id="mailHandle" value={data.mailHandle} label="Email" variant="outlined" onChange={(e)=>{handleChange(e)}}/>
        <TextField id="privacyPolicy" value={data.privacyPolicy} label="Privacy Policy" variant="outlined" onChange={(e)=>{handleChange(e)}}/>
        <TextField id="consent" value={data.consent} label="Consent" variant="outlined" onChange={(e)=>{handleChange(e)}}/>
        <TextField id="infoWeCollect" value={data.infoWeCollect} label="Information We Collect" variant="outlined" onChange={(e)=>{handleChange(e)}}/>
        <TextField id="infoUse" value={data.infoUse} label="Information Use" variant="outlined" onChange={(e)=>{handleChange(e)}}/>
        <TextField id="CCPA" value={data.CCPA} label="CCPA" variant="outlined" onChange={(e)=>{handleChange(e)}}/>
        <TextField id="GDPR" value={data.GDPR} label="GDPR" variant="outlined" onChange={(e)=>{handleChange(e)}}/>
        <TextField id="childrenInfo" value={data.childrenInfo} label="Children Information" variant="outlined" onChange={(e)=>{handleChange(e)}}/>
        <TextField id="changesToPrivacyPolicy" value={data.changesToPrivacyPolicy} label="Changes To Privacy Policy" variant="outlined" onChange={(e)=>{handleChange(e)}}/>
        {/* <TextField id="accessibility" value={data.accessibility} label="Accessibility" variant="outlined" onChange={(e)=>{handleChange(e)}}/> */}
        {/* <TextField id="FAQ" value={data.FAQ} label="FAQ" variant="outlined" onChange={(e)=>{handleChange(e)}}/> */}
    </div>
    <div style={{ textAlign: 'left' }}>
        <button className='btn' onClick={(e)=>sendForm(e)}>Submit</button>
    </div>
</form>
)
}
export default UpdateFooter