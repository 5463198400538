import React ,{useState}from 'react'
import './MobileNav.css' ;
import {Link} from 'react-router-dom'
import GetColorState from '../../Utils/GetColorState'

const MobileNav = ({url}) => {
  const { Color } = GetColorState();
  const currentPage = window.location.pathname;
  const excludedPaths = ['/login', '/register', '/dashboard', '/dashboard/delegate', '/dashboard/sponsor'];
  const shouldShowBurger = !excludedPaths.includes(currentPage);
  const [show, setShow] = useState(false)
  const [firstShow, setFirstShow] = useState(true)

  const showFunction =()=>{
    setFirstShow(false)
    setShow(!show)
  }


return (
  <div style={{color: Color.secondaryTextColor}} className="Mob_Navbar">
    <div style={{backgroundColor: Color.primaryBackgroundColor, color: Color.primaryTextColor}} className={show?"Mob_Navbar_right":(firstShow===false && show===false)?'hidden_animation':"hidee"}>
      <Link  to='/' onClick={showFunction}>HOME</Link>
      <Link to="/about" onClick={showFunction}>ABOUT</Link>
      <Link  to='/event' onClick={showFunction}>EVENT</Link>
      <Link  to="/speakers" onClick={showFunction}>SPEAKERS</Link>
      <Link to="/contact" onClick={showFunction}>CONTACT</Link>
      <Link to="/register-sponsor" onClick={showFunction}>SPONSOR</Link>
    </div>
    {shouldShowBurger && (
      <div className={show?'cross burger':'burger'} onClick={showFunction}>
        <div style={{ backgroundColor: Color.primaryTextColor }}></div>
        <div style={{ backgroundColor: Color.primaryTextColor }}></div>
      </div>
    )}
  </div>
)
}
export default MobileNav