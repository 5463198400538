import React, { useEffect } from 'react';
import './HomeCmsPage.css'
import { useSelector , useDispatch } from 'react-redux';
import {GetAllHome} from '../../features/HomeCmsSlice/HomeCmsSlice'
import {BiEditAlt} from 'react-icons/bi'
import { Link } from 'react-router-dom';


const HomePage = () => {
  const dispatch = useDispatch()
  const {Home} = useSelector((store)=>store.homeCms)
  useEffect(()=>{
    dispatch(GetAllHome())
    
  },[dispatch])
  

  return (
    <div className='home_pageCms'>
      <div style={{display:'flex', justifyContent:'flex-end',alignItems:'center',gap:'0.2rem',textAlign:'right', marginBottom:'1.5rem'}}>
        <Link style={{display:'flex', justifyContent:'center',alignItems:'center',gap:'0.2rem'}} className="btn animate-element smallPadding" to={`/dashboard/updateHome/${Home._id}`}>Edit<BiEditAlt/></Link>
      </div>

    {Home && (
      <div>

        <div className='containerCms'>
          <h6><b>Event Name: </b>{Home.eventName}</h6>
        </div>
        <div className='containerCms'>
          <h6><b>Event Collaboration: </b>{Home.eventCollab}</h6>
        </div>
        <div className='containerCms'>
          <h6><b>Company Name: </b>{Home.companyName}</h6>
        </div>
        <div className='containerCms'>
          <h6><b>Event Date: </b>{Home.eventDate}</h6>
        </div>
        <div className='containerCms'>
          <h6><b>Event City: </b>{Home.eventCity}</h6>
        </div>
        <div className='containerCms'>
          <h6><b>Event Country: </b>{Home.eventCountry}</h6>
        </div>
        <div className='containerCms' style={{display:'flex'}}>
          <h6><b>Home Banner Image: </b></h6>
          <img src={Home.homeBanner} alt="Home Banner" style={{ width: '10vw', height: '10vh' }} />
        </div>
        <div className='containerCms'>
          <h6><b>About Heading: </b>{Home.aboutHeading}</h6>
        </div>
        <div className='containerCms'>
          <h6><b>About Content: </b>{Home.aboutContent}</h6>
        </div>
        <div className='containerCms'>
          <h6><b>Previous Heading: </b>{Home.previousHeading}</h6>
        </div>
        <div className='containerCms'>
          <h6><b>Previous Content: </b>{Home.previousContent}</h6>
        </div>
        <div className='containerCms'>
          <h6><b>Motive Heading: </b>{Home.motiveHeading}</h6>
        </div>
        <div className='containerCms'>
          <h6><b>Motive Content: </b>{Home.motiveContent}</h6>
        </div>
        <div className='containerCms'>
          <h6><b>Venue Address: </b>{Home.venueAddress}</h6>
        </div>
        <div className='containerCms' style={{display:'flex'}}>
          <h6><b>Event Logo: </b></h6>
          <img src={Home.eventLogo} alt="Event Logo" style={{ width: '10vw', height: '10vh' }} />
        </div>
        <div className='containerCms' style={{display:'flex'}}>
          <h6><b>Company Logo:</b></h6>
          <img src={Home.companyLogo} alt="Company Logo" style={{ width: '10vw', height: '10vh' }} />
        </div>
      </div>
    )}
  </div>

  )
}

export default HomePage