import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const initialState = {
    Speakers:[],
    SingleSpeaker:{_id:"",name:"",designation:"",company:"",linkedIn:"",speakerImgDash:""},
    message:'',
    isLoading:false,
    isError:false,
    isSuccess:false,
}


////////////////////////////Get All speakers////////////////
export const GetAllSpeakers = createAsyncThunk('Speaker/GetAllSpeakers', async(_, thunkAPI)=>{
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/getAllDashboardSpeaker`
        const response = await axios.get(API_URL) 
        return response.data
 
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})


export const getSingleSpeaker = createAsyncThunk('Speaker/getSingleSpeaker', async(id, thunkAPI)=>{

    try{
        const token = thunkAPI.getState().auth.user.token
        const config ={
           headers:{
               Authorization: `Bearer ${token}`
           }
       }
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/getSingleDashboardSpeaker/${id}`
        const response = await axios.get(API_URL,config)
        return response.data
 
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const CreateSingleSpeaker = createAsyncThunk('Speaker/CreateSingleSpeaker', async(data, thunkAPI)=>{

    try{
        const token = thunkAPI.getState().auth.user.token
        const config ={
           headers:{
               Authorization: `Bearer ${token}`
           }
       }
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/createDashboardSpeaker`
        const response = await axios.post(API_URL, data,config)
        return response.data
 
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})


export const DeleteSingleSpeaker = createAsyncThunk('Speaker/DeleteSingleSpeaker', async(id, thunkAPI)=>{

    try{
        const token = thunkAPI.getState().auth.user.token
        const config ={
           headers:{
               Authorization: `Bearer ${token}`
           }
       }
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/deleteSingleDashboardSpeaker/${id}`
        const response = await axios.delete(API_URL,config)
        return response.data
 
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const UpdateSingleSpeaker = createAsyncThunk('Speaker/UpdateSingleSpeaker', async({formData,id}, thunkAPI)=>{

    try{
        const token = thunkAPI.getState().auth.user.token
        const config ={
           headers:{
               Authorization: `Bearer ${token}`
           }
       }
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/updateSingleDashboardSpeaker/${id}`
        const response = await axios.patch(API_URL, formData, config)
        return response.data
 
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})



export const SpeakerSlice = createSlice({
    name:'SpeakerSlice',
    initialState,
    reducers:{
        reset: (state)=> initialState,
        filterState:(state,action)=>{            
            state.Speakers = state.Speakers.filter((item) => item._id !== action.payload)
        }
    },
    extraReducers:(builder)=>{
        builder
        .addCase(GetAllSpeakers.pending, (state, action)=>{
            state.isLoading = true
        })
        .addCase(GetAllSpeakers.fulfilled, (state, action)=>{
            state.isLoading = false
            state.message = action.payload.message
            if(action.payload.success===true){
                const RequiredData = action.payload.AllDashboardSpeaker.map((listing,index) => ({
                    ...listing,
                    id: index+1,
                  }));
            state.Speakers = RequiredData
            }
            // toast("All Speaker Posts Loaded")
        })
        .addCase(GetAllSpeakers.rejected, (state, action)=>{
            state.isLoading = false
            state.isError=true
            state.message = action.payload
            state.Speakers={_id:"",name:"",designation:"",company:"",linkedIn:"",speakerImgDash:""}
            // toast("Error in Loading Speakers")
        })

        .addCase(getSingleSpeaker.pending, (state, action)=>{
            state.isLoading = true
        })
        .addCase(getSingleSpeaker.fulfilled, (state, action)=>{
            state.isSuccess=true
            state.isLoading = false
            state.message = action.payload.message
               if(action.payload.success===true){
            state.SingleSpeaker = action.payload.singleSpeakerFound
            }
        })
        .addCase(getSingleSpeaker.rejected, (state, action)=>{
            state.isSuccess=false
            state.isLoading = false
            state.isError=true
            state.message = action.payload
            state.SingleSpeaker={_id:"",name:"",designation:"",company:"",linkedIn:"",speakerImgDash:""}
        })



        .addCase(CreateSingleSpeaker.pending, (state, action)=>{
            state.isLoading = true
        })
        .addCase(CreateSingleSpeaker.fulfilled, (state, action)=>{
            state.isLoading = false
            state.message = action.payload.message
            toast(action.payload.message)
        })
        .addCase(CreateSingleSpeaker.rejected, (state, action)=>{
            state.isLoading = false
            state.isError=true
            state.message = action.payload
            toast(action.payload)
        })



        .addCase(DeleteSingleSpeaker.pending, (state, action)=>{
            state.isLoading = true
        })
        .addCase(DeleteSingleSpeaker.fulfilled, (state, action)=>{
            state.isLoading = false
            state.message = action.payload.message
            toast(action.payload.message)
            
        })
        .addCase(DeleteSingleSpeaker.rejected, (state, action)=>{
            state.isLoading = false
            state.isError=true
            state.message = action.payload
            toast(action.payload)

        })


        .addCase(UpdateSingleSpeaker.pending, (state, action)=>{
            state.isLoading = true
        })
        .addCase(UpdateSingleSpeaker.fulfilled, (state, action)=>{
            state.isSuccess=true
            state.isLoading = false
            state.message = action.payload.message
            if(action.payload.success===true){
                state.SingleSpeaker = action.payload.updatedSpeaker
                toast(state.message)
            }
        })
        .addCase(UpdateSingleSpeaker.rejected, (state, action)=>{
            state.isSuccess=false
            state.isLoading = false
            state.isError=true
            state.message = action.payload
            toast(action.payload)
            state.SingleSpeaker={_id:"",name:"",designation:"",company:"",linkedIn:"",speakerImgDash:""}
        })
    }
})


export const {reset,filterState} = SpeakerSlice.actions
export default SpeakerSlice.reducer