import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { CreateDelegateTicketCms, GetAllDelegateTicketCms } from '../../features/DelegateTicketCmsSlice/DelegateTicketCmsSlice';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const CreateDelegateTicket = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [data, setData] = useState({
        DelegateTicketName: '',
        DelegateTicketPrice:''
    });

const handleChange = (e) => {
    setData({ ...data, [e.target.id]: e.target.value });
};


const sendForm = (e) => {
    e.preventDefault();
    dispatch(CreateDelegateTicketCms(data))
    .then(() => {
        dispatch(GetAllDelegateTicketCms());
        navigate('/dashboard/delegateticket');
    })
    .catch((error) => {
        toast.error('Error creating DelegateTicket: ' + error.message);
    });
};


return (
    <form className="CreateCareer">
    <div className="form_left">
        <TextField id="DelegateTicketName" label="Ticket Name" variant="outlined" onChange={(e) => handleChange(e)} />
        <TextField type='number' inputProps={{ min: "0", placeholder:"" }} id="DelegateTicketPrice" label="Ticket Price in dollors" variant="outlined" onChange={(e) => handleChange(e)} />
    </div>
    <div style={{ textAlign: 'left' }}>
        <button className="btn" onClick={(e) => sendForm(e)}>Submit</button>
    </div>
    </form>
);
};

export default CreateDelegateTicket;
