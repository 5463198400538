import React, { useEffect } from 'react';
import { useSelector , useDispatch } from 'react-redux';
import {GetAllColor} from '../../features/ColorCmsSlice/ColorCmsSlice'
import {BiEditAlt} from 'react-icons/bi'
import { Link } from 'react-router-dom';


const ColorPage = () => {
  const dispatch = useDispatch()

  const {Color} = useSelector((store)=>store.colorCms)

  useEffect(()=>{
    dispatch(GetAllColor())
  },[dispatch])

return (
    <div className='home_pageCms'>
      <div style={{display:'flex', justifyContent:'flex-end',alignItems:'center',gap:'0.2rem',textAlign:'right', marginBottom:'1.5rem'}}>
        <Link style={{display:'flex', justifyContent:'center',alignItems:'center',gap:'0.2rem'}} className="btn animate-element smallPadding" to={`/dashboard/updateColor/${Color._id}`}>Edit<BiEditAlt/></Link>
      </div>

    {Color && (
        <div>
        <div className='containerCms' style={{display:'flex', alignItems:'center'}}>
          <h6><b>Primary Background Color:</b> {Color.primaryBackgroundColor}</h6>
          <div style={{ backgroundColor: Color.primaryBackgroundColor, width: '20px', height: '20px', marginLeft: '10px', border: '1px solid #ccc' }}></div>
        </div>
        <div className='containerCms' style={{display:'flex', alignItems:'center'}}>
          <h6><b>Secondary Background Color:</b> {Color.secondaryBackgroundColor}</h6>
          <div style={{ backgroundColor: Color.secondaryBackgroundColor, width: '20px', height: '20px', marginLeft: '10px', border: '1px solid #ccc' }}></div>
        </div>
        <div className='containerCms' style={{display:'flex', alignItems:'center'}}>
          <h6><b>Primary Text Color:</b> {Color.primaryTextColor}</h6>
          <div style={{ backgroundColor: Color.primaryTextColor, width: '20px', height: '20px', marginLeft: '10px', border: '1px solid #ccc' }}></div>
        </div>
        <div className='containerCms' style={{display:'flex', alignItems:'center'}}>
          <h6><b>Secondary Text Color:</b> {Color.secondaryTextColor}</h6>
          <div style={{ backgroundColor: Color.secondaryTextColor, width: '20px', height: '20px', marginLeft: '10px', border: '1px solid #ccc' }}></div>
        </div>
      </div>
  )}
  </div>

  )
}

export default ColorPage