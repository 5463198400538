// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
  width: 100vw;
}

.login-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 5rem;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 0 20px; /* Add margin for screen sizes below 500px */
}

.form-group {
  margin-bottom: 15px;
  width: 100%;
}

.form-group > input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

@media only screen and (max-width: 500px) {
  .login-form-container {
    padding: 4rem;
    width: 400px;
  }
}

@media only screen and (max-width: 400px) {
  .login-form-container {
    padding: 3rem;
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/CmsPages/Login/Login.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,aAAa;EACb,WAAW;EACX,sBAAsB;EACtB,2CAA2C;EAC3C,kBAAkB;EAClB,cAAc,EAAE,4CAA4C;AAC9D;;AAEA;EACE,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE;IACE,aAAa;IACb,YAAY;EACd;AACF;;AAEA;EACE;IACE,aAAa;IACb,WAAW;EACb;AACF","sourcesContent":[".login-container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n  background-color: #f5f5f5;\n  width: 100vw;\n}\n\n.login-form-container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n  padding: 5rem;\n  width: 100%;\n  background-color: #fff;\n  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);\n  border-radius: 8px;\n  margin: 0 20px; /* Add margin for screen sizes below 500px */\n}\n\n.form-group {\n  margin-bottom: 15px;\n  width: 100%;\n}\n\n.form-group > input {\n  width: 100%;\n  padding: 10px;\n  border: 1px solid #ddd;\n  border-radius: 5px;\n  font-size: 16px;\n}\n\n@media only screen and (max-width: 500px) {\n  .login-form-container {\n    padding: 4rem;\n    width: 400px;\n  }\n}\n\n@media only screen and (max-width: 400px) {\n  .login-form-container {\n    padding: 3rem;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
