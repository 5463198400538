import './AboutUs.css'
import GetHomeState from '../../Utils/GetHomeState'

const AboutUs = () => {
  const { Home } = GetHomeState();

  return (
    <div className='flex_row_c aboutDiv'>
        <h4>{Home.aboutHeading}</h4>
        <p>{Home.aboutContent}</p>
    </div>
  )
}
export default AboutUs