import React, { useEffect } from 'react';
import { useSelector , useDispatch } from 'react-redux';
import {GetAllSponsorTicketCms , filterState,DeleteSingleSponsorTicketCms} from '../../features/SponsorTicketCmsSlice/SponsorTicketCmsSlice'
import DataTable from '../../CmsComponents/DataTableSponsorTicket/DataTableSponsorTicket';
import {RiDeleteBin7Line} from 'react-icons/ri'

const SponsorTicketCmsPage = () => {
  const dispatch = useDispatch()

  const deleteSponsorTicketCms=async(id)=>{
    dispatch(DeleteSingleSponsorTicketCms(id));
    dispatch(filterState(id))
    
  }
  const SponsorTicketCmsColumns = [
    {
      field: 'id', 
      headerName: 'Sr.No',
      width: 70,
    },
    { field: 'SponsorTicketName', headerName: 'Ticket Name', width: 200 },
    { field: 'SponsorTicketPrice', headerName: 'Ticket Price', width: 130 },
    { field: 'perks', headerName: 'Perks', width: 650 },
    {field: "Action",headerName: "Action",width: 120, renderCell: (params) => 
    <div style={{display:"flex", alignItems:"center", gap:"1rem"}}>
      <button style={{fontSize:"1.5rem", color:"#3166BB", cursor:"pointer", background:'none', border:'none'}} onClick={()=>deleteSponsorTicketCms(params.row._id)}><RiDeleteBin7Line/></button>
    </div>},
  
  ];
  const {SponsorTicketCms} = useSelector((store)=>store.sponsorTicketCms)
  useEffect(()=>{
    dispatch(GetAllSponsorTicketCms())
    
  },[dispatch])
  



  return (
    <div>
    {SponsorTicketCms ? (
      <DataTable columns={SponsorTicketCmsColumns} data={SponsorTicketCms} link="/dashboard/create-sponsorticket" />
    ) : (
      <DataTable columns={SponsorTicketCmsColumns} data={[]} link="/dashboard/create-sponsorticket" />
    )}

    </div>
  )
}

export default SponsorTicketCmsPage