import './Agenda.css'
import GetHomeState from '../../Utils/GetHomeState'

const Agenda = () => {
  const { Home } = GetHomeState();

  return (
    <div className="Agenda">
        <div className='Agenda_bg'></div>
        <div className='flex_row_c text_AgendaCT'>
          <div>
            <h4>{Home.motiveHeading}</h4>
          </div>
        <p>{Home.motiveContent}</p>
        </div>      
    </div>
  )
}
export default Agenda