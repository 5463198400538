import "./Hero.css"
import Header from "../Header/Header";
import GetHomeState from '../../Utils/GetHomeState'
import GetColorState from '../../Utils/GetColorState'


const Hero = () => {
  const { Home } = GetHomeState();
  const { Color } = GetColorState();
  const date = new Date(Home.eventDate)
  const options = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  };


  return (
    <div className="hero" style={{backgroundImage: `url(${Home.homeBanner})`}} id="hero">
      <Header/>
      <div className="pinkHero"></div>
      <div className="home_heading">
        <h2 className="separateLine">{Home.eventName}</h2>
        <ul className="ul">
        {Home && <li>{date.toLocaleDateString('en-PK', options)}</li>}
        {Home && <li>{Home.eventCity},{Home.eventCountry}</li>}
        </ul>
        <ul className="ul">
          {Home && <li>{Home.eventCollab}</li>}
        </ul>
      </div>
    </div>
  )
}
export default Hero