import {createSlice,createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {toast} from 'react-toastify'
const initialState ={
    url:"",
    fullName:"",
    email:"",
    contact:"",
    company:"",
    jobTitle:"",
    country:"",
    id:"",
    counter:"",
    isSuccess : false,
    isLoading: false,
    isError:false,
    message:""
}


export const registerDelegate  = createAsyncThunk("register/delegate", async(formData, thunkAPI)=>{  
    try{
        const config = {
            headers:{
              'content-type': 'multipart/form-data'
            }
          }
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/addDelegate`
        const response = await axios.post(API_URL, formData,config)
        const data =  await response.data
        return data

    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }

})



////////////////////////////register sponsor//////////////
export const registerSponsor  = createAsyncThunk("register/sponsor", async(data, thunkAPI)=>{
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/addSponsor`
        const response = await axios.post(API_URL, data )
        const responseData =  await response.data
        return responseData

    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})




///////////////subscription to news letter///////////////////
export const Subscription = createAsyncThunk('register/Subscription',async(email, thunkAPI)=>{
    try {
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/user/subscription`;
        const response = await axios.post(API_URL,email)
        return response.data
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})





export const InvoiceSlice =  createSlice({ 
    name:"register",
    initialState,
    reducers:{
        reset:(state)=>{
            state.url="";
            state.isSuccess=false;
            state.isLoading= false;
            state.isError=false;
    },
    },
    extraReducers:(builder)=>{
        builder
        .addCase(registerDelegate.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(registerDelegate.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.isSuccess =  true;
            state.message = action.payload.message;
            toast(action.payload.message)
            window.location.replace(action.payload.url);
        })
        .addCase(registerDelegate.rejected, (state, action)=>{
            state.isError=true;
            state.isLoading = false;
            state.message = action.payload.message;
        })


        .addCase(registerSponsor.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(registerSponsor.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.isSuccess =  true
            if(action.payload.success === true){
                window.location.replace(action.payload.url)
            }
            state.message = action.payload.message;
            toast(action.payload.message)
        })
        .addCase(registerSponsor.rejected, (state, action)=>{
            state.isError=true;
            state.isLoading = false;
            state.message = action.payload.message;
        })

        .addCase(Subscription.pending,(state)=>{
            state.isLoading = true
        })
        .addCase(Subscription.fulfilled,(state , action)=>{
            state.isLoading = false
            state.isSuccess = true
            if(action.payload.success === true){
                toast.success(action.payload.message)
            }else{
                toast.info(action.payload.message)
            }
            state.message = action.payload.message
        })
        .addCase(Subscription.rejected,(state, action)=>{
            state.isLoading = false
            state.isError = true
            state.message = action.payload.message
        })
    }
})


export const {reset} = InvoiceSlice.actions
export default InvoiceSlice.reducer
