import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const initialState = {
    About:'',
    message:'',
    isLoading:false,
    isError:false,
    isSuccess:false,
}


////////////////////////////Get All About////////////////
export const GetAllAbout = createAsyncThunk('About/getCmsAbout', async(_, thunkAPI)=>{
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/getCmsAbout`
        const response = await axios.get(API_URL)
        return response.data
 
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})


export const UpdateAllAbout = createAsyncThunk('Speaker/UpdateCmsAbout', async({formData,id}, thunkAPI)=>{

    try{
        const token = thunkAPI.getState().auth.user.token
        const config ={
           headers:{
               Authorization: `Bearer ${token}`
           }
       }
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/updateCmsAbout/${id}`
        const response = await axios.patch(API_URL, formData, config)
        return response.data
 
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})



export const AboutSlice = createSlice({
    name:'AboutSlice',
    initialState,
    reducers:{
        reset: (state)=> initialState,
        filterState:(state,action)=>{            
            state.About = state.About.filter((item) => item._id !== action.payload)
        }
    },
    extraReducers:(builder)=>{
        builder
        .addCase(GetAllAbout.pending, (state, action)=>{
            state.isLoading = true
        })
        .addCase(GetAllAbout.fulfilled, (state, action)=>{
            state.isLoading = false
            state.message = action.payload.message
            state.isSuccess = true
            if(action.payload.success===true){
                state.About = action.payload.cmsAbout
            }
        })
        .addCase(GetAllAbout.rejected, (state, action)=>{
            state.isLoading = false
            state.isError=true
            state.message = action.payload
            state.About={_id:"",theAboutHeading:"",theAboutContent:"",replayAboutHeading:"",replayAboutContent:"",collabHeading:"",collabContent:"",aboutImg1:"",aboutImg2:""}
        })

        .addCase(UpdateAllAbout.pending, (state, action)=>{
            state.isLoading = true
        })
        .addCase(UpdateAllAbout.fulfilled, (state, action)=>{
            state.isSuccess=true
            state.isLoading = false
            state.message = action.payload.message
               if(action.payload.success===true){
                state.About = action.payload.updatedAbout
                toast(state.message)
            }
        })
        .addCase(UpdateAllAbout.rejected, (state, action)=>{
            state.isSuccess=false
            state.isLoading = false
            state.isError=true
            state.message = action.payload
            toast(action.payload)
            state.About={_id:"",theAboutHeading:"",theAboutContent:"",replayAboutHeading:"",replayAboutContent:"",collabHeading:"",collabContent:"",aboutImg1:"",aboutImg2:""}
        })
    }
})


export const {reset,filterState} = AboutSlice.actions
export default AboutSlice.reducer