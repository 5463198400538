import React, { useEffect } from 'react';
import { useSelector , useDispatch } from 'react-redux';
import {GetAllDelegateTicketCms , filterState,DeleteSingleDelegateTicketCms} from '../../features/DelegateTicketCmsSlice/DelegateTicketCmsSlice'
import DataTable from '../../CmsComponents/DataTableDelegateTicket/DataTableDelegateTicket';
import {RiDeleteBin7Line} from 'react-icons/ri'

const DelegateTicketCmsPage = () => {
  const dispatch = useDispatch()

  const deleteDelegateTicketCms=async(id)=>{
    dispatch(DeleteSingleDelegateTicketCms(id));
    dispatch(filterState(id))
    
  }
  const DelegateTicketCmsColumns = [
    {
      field: 'id', 
      headerName: 'Sr.No',
      width: 70,
    },
    { field: 'DelegateTicketName', headerName: 'Ticket Name', width: 200 },
    { field: 'DelegateTicketPrice', headerName: 'Ticket Price', width: 800 },
    {field: "Action",headerName: "Action",width: 120, renderCell: (params) => 
    <div style={{display:"flex", alignItems:"center", gap:"1rem"}}>
      <button style={{fontSize:"1.5rem", color:"#3166BB", cursor:"pointer", background:'none', border:'none'}} onClick={()=>deleteDelegateTicketCms(params.row._id)}><RiDeleteBin7Line/></button>
    </div>},
  
  ];
  const {DelegateTicketCms} = useSelector((store)=>store.delegateTicketCms)
  useEffect(()=>{
    dispatch(GetAllDelegateTicketCms())
    
  },[dispatch])
  



  return (
    <div>
    {DelegateTicketCms ? (
      <DataTable columns={DelegateTicketCmsColumns} data={DelegateTicketCms} link="/dashboard/create-delegateticket" />
    ) : (
      <DataTable columns={DelegateTicketCmsColumns} data={[]} link="/dashboard/create-delegateticket" />
    )}

    </div>
  )
}

export default DelegateTicketCmsPage