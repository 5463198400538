import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GetAllSpeakers } from '../features/SpeakerCmsSlice/SpeakerCmsSlice'

function GetSpeakerCmsState() {
    const {Speakers, isSuccess } = useSelector((store) => store.speakerCms);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GetAllSpeakers());
    }, [isSuccess, dispatch]);
return {Speakers, isSuccess };
}
export default GetSpeakerCmsState;