import './SpeakerList.css';
import GetSpeakerState from '../../Utils/GetSpeakerState'


const SpeakerList = () => {
  const { Speakers } = GetSpeakerState();

  return (
    <div className='speaker_text'>
      <div>
        <h2>Our Speakers</h2> 
      </div>
      {Speakers.length > 0 ? (
        <div className='speaker2023'>
        {Speakers.map((speaker, index) => (
          <div className='speaker23Div' key={index}>
            <a href={speaker.linkedIn}>
              <div className='speakerimg23' key={index}>
                <img src={speaker.speakerImgDash} alt={speaker.name} />
              </div>
            </a>
            <div className='detailsBelow'>
              <p>{speaker.name}</p>
              <div>
                <span>{speaker.designation}</span>
                <span> | </span>
                <span>{speaker.company}</span>
              </div>
            </div>
          </div>
        ))
        }
    </div>       
    ):<h5 style={{textAlign:"center"}}>TBH Soon</h5>}
    </div>

  );
};

export default SpeakerList;
