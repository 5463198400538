import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GetAllPrevYearSpeakers } from '../features/PrevYearSpeakerCmsSlice/PrevYearSpeakerCmsSlice'

function GetPrevYearSpeakerCmsState() {
    const { PrevYearSpeakers, isSuccess } = useSelector((store) => store.prevYearspeakerCms);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GetAllPrevYearSpeakers());
    }, [isSuccess, dispatch]);
return {PrevYearSpeakers, isSuccess };
}
export default GetPrevYearSpeakerCmsState;