import React, { useEffect,useState } from 'react'
import { useSelector , useDispatch } from 'react-redux';
import {toast} from 'react-toastify'
import { useLocation, useNavigate } from 'react-router-dom';
import {UpdateAllColor, GetAllColor} from '../../features/ColorCmsSlice/ColorCmsSlice'



const UpdateColor = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location  = useLocation();
    const id = location.pathname.substring(location.pathname.lastIndexOf('/')+1)
    const [data, setData] = useState({
        primaryBackgroundColor: '#000000',
        secondaryBackgroundColor: '#e6007e',
        primaryTextColor: '#ffffff',
        secondaryTextColor:'#000000',
    });

    const ValueUpdater = (id, value) => {
        setData({ ...data, [id]: value,
        });
    };

const sendForm = (e)=>{
    e.preventDefault()
    if (data.primaryBackgroundColor !== '' && data.secondaryBackgroundColor !== '' && data.primaryTextColor !== '' && data.secondaryTextColor !== '') {
        if (data.primaryBackgroundColor !== data.primaryTextColor  && data.secondaryBackgroundColor !== data.secondaryTextColor) {
            dispatch(UpdateAllColor({data,id}))
            navigate('/dashboard/color');
        }else{
            toast.info("The background color must not match the text color");
        }
    } else {
        toast('Please fill out the form completely.');
    }

    }
let {Color, isSuccess} = useSelector((store)=>store.colorCms)
Color = Color ? Color : null;

useEffect(()=>{
    dispatch(GetAllColor())
    if(isSuccess && Color){
        setData({ primaryBackgroundColor:Color.primaryBackgroundColor, secondaryBackgroundColor:Color.secondaryBackgroundColor, primaryTextColor:Color.primaryTextColor, secondaryTextColor:Color.secondaryTextColor })
    }
},[isSuccess])

return (
    <form className='CreateCareer'> 
    <div className="form_left">
        <div style={{display:'flex',flexDirection:'column' , gap:'0.5rem'}}>
            <label htmlFor="color">Primary Background Color</label>
            <input style={{height:'3rem'}} type="color" id='primaryBackgroundColor' value={data.primaryBackgroundColor} onChange={(e) => ValueUpdater('primaryBackgroundColor', e.target.value)}/>
        </div>
        <div style={{display:'flex',flexDirection:'column' , gap:'0.5rem'}}>
            <label htmlFor="color">Secondary Background Color</label>
            <input style={{height:'3rem'}} type="color" id='secondaryBackgroundColor' value={data.secondaryBackgroundColor} onChange={(e) => ValueUpdater('secondaryBackgroundColor', e.target.value)}/>
        </div>
        <div style={{display:'flex',flexDirection:'column' , gap:'0.5rem'}}>
            <label htmlFor="color">Primary Text Color</label>
            <input style={{height:'3rem'}} type="color" id='primaryTextColor' value={data.primaryTextColor} onChange={(e) => ValueUpdater('primaryTextColor', e.target.value)}/>
        </div>
        <div style={{display:'flex',flexDirection:'column' , gap:'0.5rem'}}>
            <label htmlFor="color">Secondary Text Color</label>
            <input style={{height:'3rem'}} type="color" id='secondaryTextColor' value={data.secondaryTextColor} onChange={(e) => ValueUpdater('secondaryTextColor', e.target.value)}/>
        </div>    
    </div>
    <div style={{ textAlign: 'left' }}>
        <button className='btn' onClick={(e)=>sendForm(e)}>Submit</button>
    </div>
</form>
)
}
export default UpdateColor