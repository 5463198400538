import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const initialState = {
    Partners:[],
    message:'',
    isLoading:false,
    isError:false,
    isSuccess:false,
}



////////////////////////////Get All Partners////////////////
export const GetAllPartners = createAsyncThunk('Speaker/GetAllPartners', async(_, thunkAPI)=>{
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/getAllCmsPartner`
        const response = await axios.get(API_URL)
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})


export const CreateSinglePartner = createAsyncThunk('Speaker/CreateSinglePartner', async(data, thunkAPI)=>{
    try{
        const token = thunkAPI.getState().auth.user.token
        const config ={
            headers:{
                Authorization: `Bearer ${token}`
            }
        }
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/createCmsPartner`
        const response = await axios.post(API_URL, data, config)
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
        }
})


export const DeleteSinglePartner = createAsyncThunk('Speaker/DeleteSinglePartner', async(id, thunkAPI)=>{
    try{
        const token = thunkAPI.getState().auth.user.token
        const config ={
            headers:{
                Authorization: `Bearer ${token}`
            }
        }
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/deleteSingleCmsPartner/${id}`
        const response = await axios.delete(API_URL, config)
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})


export const Partnerslice = createSlice({
    name:'Partnerslice',
    initialState,
    reducers:{
        reset: (state)=> initialState,
        filterState:(state,action)=>{            
            state.Partners = state.Partners.filter((item) => item._id !== action.payload)
        }
    },
    extraReducers:(builder)=>{
        builder
        .addCase(GetAllPartners.pending, (state, action)=>{
            state.isLoading = true
        })
        .addCase(GetAllPartners.fulfilled, (state, action)=>{
            state.isLoading = false
            state.message = action.payload.message
            if(action.payload.success===true){
                const RequiredData = action.payload.AllCmsPartner.map((listing,index) => ({
                    ...listing,
                    id: index+1,
                }));
            state.Partners = RequiredData
            }
        })
        .addCase(GetAllPartners.rejected, (state, action)=>{
            state.isLoading = false
            state.isError=true
            state.message = action.payload
            state.Partners={_id:"",partnerImg:""}
        })

        .addCase(CreateSinglePartner.pending, (state, action)=>{
            state.isLoading = true
        })
        .addCase(CreateSinglePartner.fulfilled, (state, action)=>{
            state.isLoading = false
            state.message = action.payload.message
            toast(action.payload.message)
        })
        .addCase(CreateSinglePartner.rejected, (state, action)=>{
            state.isLoading = false
            state.isError=true
            state.message = action.payload
            toast(action.payload)
        })

        .addCase(DeleteSinglePartner.pending, (state, action)=>{
            state.isLoading = true
        })
        .addCase(DeleteSinglePartner.fulfilled, (state, action)=>{
            state.isLoading = false
            state.message = action.payload.message
            toast(action.payload.message)
        })
        .addCase(DeleteSinglePartner.rejected, (state, action)=>{
            state.isLoading = false
            state.isError=true
            state.message = action.payload
            toast(action.payload)
        })
    }
})


export const {reset,filterState} = Partnerslice.actions
export default Partnerslice.reducer