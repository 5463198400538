import React, { useEffect, useState } from 'react';
// import './PartnerCmsPage.css';
import TextField from '@mui/material/TextField';
import { CreateSponsorTicketCms, GetAllSponsorTicketCms } from '../../features/SponsorTicketCmsSlice/SponsorTicketCmsSlice';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const CreateSponsorTicket = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [perks, setPerks]= useState("")
    const [data, setData] = useState({
        SponsorTicketName: '',
        SponsorTicketPrice:'',
        perks:[]
    });

const handleChange = (e) => {
    if(e.target.id==="perks"){
        setPerks(e.target.value)
    }else{
        setData({ ...data, [e.target.id]: e.target.value });
    }
};

const handleSubmit=(e)=>{
    e.preventDefault()
    if(e.target.id==="perks"){
        if(perks!=="" && perks!==undefined){
        setData({...data,[e.target.id]:[...data.perks,perks]})
        setPerks("")
        }
    }
}

const chnageListfields=(e,listType,index)=>{    
    if(listType==="perks"){
        setData(prevData => {
            const Updateperks = [...prevData.perks];
            Updateperks[index] = e.target.value;
            return { ...prevData, perks: Updateperks };
        });
    }
}


const sendForm = (e) => {
    e.preventDefault();
    dispatch(CreateSponsorTicketCms(data))
    .then(() => {
        dispatch(GetAllSponsorTicketCms());
        navigate('/dashboard/sponsorticket');
    })
    .catch((error) => {
        toast.error('Error creating sponsorTicket: ' + error.message);
    });
};


return (
    <form className="CreateCareer">
    <div className="form_left">
        <TextField id="SponsorTicketName" label="Ticket Name" variant="outlined" onChange={(e) => handleChange(e)} />
        <TextField type='number' inputProps={{ min: "0", placeholder:"" }} id="SponsorTicketPrice" label="Ticket Price in dollors" variant="outlined" onChange={(e) => handleChange(e)} />
        <div className='form_right_left'>
            <div className='input_btn'>
                <TextField id="perks" label="Perks" variant="outlined" value={perks} onChange={(e)=>{handleChange(e)}}/>
                <button id="perks" onClick={(e)=>handleSubmit(e)}>+</button>
            </div>
            <ul className='List_of_string'>
            {data.perks.map((item,i)=>{
                return(
                    <input value={item} type="text" onChange={(e)=>chnageListfields(e,"perks",i)}/>
                ) 
            })}
            </ul>
        </div>
    </div>
    <div style={{ textAlign: 'left' }}>
        <button className="btn" onClick={(e) => sendForm(e)}>Submit</button>
    </div>
    </form>
);
};

export default CreateSponsorTicket;
