// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subscribe-popup {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 2rem;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
    padding: 2px 5px !important;

  }
  .newsletterDiv{
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  input {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 16px;
  }
  @media only screen and (max-width: 768px) {
    .subscribe-popup {
      bottom: 10px;
      right: 10px;
      padding: 10px;
    }
  
    .close-button {
      top: 5px;
      right: 5px;
      font-size: 16px;
    }
  
    h3 {
      font-size: 18px;
      margin-bottom: 10px;
    }
  
    input {
      padding: 8px;
      font-size: 14px;
    }
  
    button {
      padding: 8px 16px;
      font-size: 14px;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/SubscribePopup/SubscribePopup.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,WAAW;IACX,aAAa;IACb,kBAAkB;IAClB,2CAA2C;IAC3C,aAAa;EACf;;EAEA;IACE,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,eAAe;IACf,eAAe;IACf,2BAA2B;;EAE7B;EACA;IACE,aAAa;IACb,sBAAsB;IACtB,SAAS;EACX;;;EAGA;IACE,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;EACjB;EACA;IACE;MACE,YAAY;MACZ,WAAW;MACX,aAAa;IACf;;IAEA;MACE,QAAQ;MACR,UAAU;MACV,eAAe;IACjB;;IAEA;MACE,eAAe;MACf,mBAAmB;IACrB;;IAEA;MACE,YAAY;MACZ,eAAe;IACjB;;IAEA;MACE,iBAAiB;MACjB,eAAe;IACjB;EACF","sourcesContent":[".subscribe-popup {\n    position: fixed;\n    bottom: 20px;\n    right: 20px;\n    padding: 2rem;\n    border-radius: 5px;\n    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);\n    z-index: 1000;\n  }\n  \n  .close-button {\n    position: absolute;\n    top: 10px;\n    right: 10px;\n    font-size: 20px;\n    cursor: pointer;\n    padding: 2px 5px !important;\n\n  }\n  .newsletterDiv{\n    display: flex;\n    flex-direction: column;\n    gap: 1rem;\n  }\n  \n  \n  form {\n    display: flex;\n    flex-direction: column;\n  }\n  \n  input {\n    margin-bottom: 10px;\n    padding: 10px;\n    border: 1px solid #ccc;\n    border-radius: 3px;\n    font-size: 16px;\n  }\n  @media only screen and (max-width: 768px) {\n    .subscribe-popup {\n      bottom: 10px;\n      right: 10px;\n      padding: 10px;\n    }\n  \n    .close-button {\n      top: 5px;\n      right: 5px;\n      font-size: 16px;\n    }\n  \n    h3 {\n      font-size: 18px;\n      margin-bottom: 10px;\n    }\n  \n    input {\n      padding: 8px;\n      font-size: 14px;\n    }\n  \n    button {\n      padding: 8px 16px;\n      font-size: 14px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
