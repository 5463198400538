// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.speaker_text{
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;
    gap: 3rem;
    padding: 0rem 5rem;
    margin-top: 5rem;
}
.tbf{
    margin: 0rem 0rem 2rem 0rem;
}

.speaker_text>div>h2{
    text-decoration: underline;
    text-decoration-thickness: 5px;
    text-underline-offset: 0.8rem;
}
.speaker_text>h2{
    text-decoration: underline;
    text-decoration-thickness: 5px;
    text-underline-offset: 0.8rem;

}


@media only screen and (max-width: 1200px) {
    .speaker_text{
        padding: 0rem 3rem;
    }
}
@media only screen and (max-width: 800px) {
    .speaker_text{
        margin-top: 3rem;
    }
}
@media only screen and (max-width: 550px) {
    .speaker_text{
        padding: 0rem 2rem 0rem 2rem;
        margin-top: 2rem;
        gap: 2rem;
    }
    .speaker_text>div{
        margin-top: 0rem;
        margin-bottom: 0rem;   
    }
}`, "",{"version":3,"sources":["webpack://./src/Pages/Speakers/Speakers.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,kBAAkB;IAClB,gBAAgB;AACpB;AACA;IACI,2BAA2B;AAC/B;;AAEA;IACI,0BAA0B;IAC1B,8BAA8B;IAC9B,6BAA6B;AACjC;AACA;IACI,0BAA0B;IAC1B,8BAA8B;IAC9B,6BAA6B;;AAEjC;;;AAGA;IACI;QACI,kBAAkB;IACtB;AACJ;AACA;IACI;QACI,gBAAgB;IACpB;AACJ;AACA;IACI;QACI,4BAA4B;QAC5B,gBAAgB;QAChB,SAAS;IACb;IACA;QACI,gBAAgB;QAChB,mBAAmB;IACvB;AACJ","sourcesContent":[".speaker_text{\n    display: flex;\n    flex-direction: column;\n    position: relative;\n    z-index: 2;\n    gap: 3rem;\n    padding: 0rem 5rem;\n    margin-top: 5rem;\n}\n.tbf{\n    margin: 0rem 0rem 2rem 0rem;\n}\n\n.speaker_text>div>h2{\n    text-decoration: underline;\n    text-decoration-thickness: 5px;\n    text-underline-offset: 0.8rem;\n}\n.speaker_text>h2{\n    text-decoration: underline;\n    text-decoration-thickness: 5px;\n    text-underline-offset: 0.8rem;\n\n}\n\n\n@media only screen and (max-width: 1200px) {\n    .speaker_text{\n        padding: 0rem 3rem;\n    }\n}\n@media only screen and (max-width: 800px) {\n    .speaker_text{\n        margin-top: 3rem;\n    }\n}\n@media only screen and (max-width: 550px) {\n    .speaker_text{\n        padding: 0rem 2rem 0rem 2rem;\n        margin-top: 2rem;\n        gap: 2rem;\n    }\n    .speaker_text>div{\n        margin-top: 0rem;\n        margin-bottom: 0rem;   \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
