import React, { useEffect,useState } from 'react'
import { useSelector , useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import {toast} from 'react-toastify'
import { useLocation, useNavigate } from 'react-router-dom';
import {UpdateAllEvent,reset, GetAllEvent} from '../../features/EventCmsSlice/EventCmsSlice'



const UpdateEvent = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location  = useLocation();
    const id = location.pathname.substring(location.pathname.lastIndexOf('/')+1)
    const [data, setData] = useState({
        eventBannerImage: null,
        agendaCanvaLink: '',
    });

const handleChange =(e)=>{
    setData({ ...data, [e.target.id]: e.target.value });
    }

const sendForm = (e)=>{
    e.preventDefault()
    if (data.agendaCanvaLink !== '' && data.eventBannerImage !== null) {
        const formData = new FormData();
        formData.append('agendaCanvaLink', data.agendaCanvaLink);

        if (data.eventBannerImage !== null) {
            formData.append('eventBannerImage', data.eventBannerImage);
            dispatch(UpdateAllEvent({ formData, id }))
            .then(() => {
              dispatch(GetAllEvent());
              navigate('/dashboard/event');
            })
            .catch((error) => {
              toast.error('Error updating event: ' + error.message);
            });
        } else {
          toast('Please upload event banner');
        }
    
      } else {
        toast('Please fill out the form completely.');
      }
    }
let {Event, isSuccess} = useSelector((store)=>store.eventCms)
Event = Event ? Event : null;

useEffect(()=>{
    dispatch(GetAllEvent())
    if(isSuccess && Event){
        setData({ agendaCanvaLink:Event.agendaCanvaLink, eventBannerImage:null })
    }
},[isSuccess])

return (
    <form className='CreateCareer'> 
    <div className="form_left">
        <div style={{display:'flex',flexDirection:'column' , gap:'0.5rem'}}>
            <label htmlFor="eventBannerImage">Banner Image</label>
            <input id="eventBannerImage" label="Event Logo" type="file" accept="image/*" onChange={(e)=>setData({...data,["eventBannerImage"]:e.target.files[0]})}/>
        </div>
        <TextField id="agendaCanvaLink" value={data.agendaCanvaLink} label="Canva Link" variant="outlined" onChange={(e)=>{handleChange(e)}}/>
    </div>
    <div style={{ textAlign: 'left' }}>
        <button className='btn' onClick={(e)=>sendForm(e)}>Submit</button>
    </div>
</form>
)
}

export default UpdateEvent