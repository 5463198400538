import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GetAllFooter } from '../features/FooterCmsSlice/FooterCmsSlice'

function GetFooterState() {
    const { Footer, isSuccess } = useSelector((store) => store.footerCms);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GetAllFooter());
    }, [isSuccess, dispatch]);
return { Footer, isSuccess };
}
export default GetFooterState;