import React, { useEffect } from 'react';
import { useSelector , useDispatch } from 'react-redux';
import {GetAllEvent , filterState} from '../../features/EventCmsSlice/EventCmsSlice'
import {BiEditAlt} from 'react-icons/bi'
import { Link } from 'react-router-dom';


const EventCmsPage = () => {
  const dispatch = useDispatch()

  const {Event} = useSelector((store)=>store.eventCms)
  useEffect(()=>{
    dispatch(GetAllEvent())
  },[dispatch])
  

  return (
    <div className='home_pageCms'>
      <div style={{display:'flex', justifyContent:'flex-end',alignItems:'center',gap:'0.2rem',textAlign:'right', marginBottom:'1.5rem'}}>
        <Link style={{display:'flex', justifyContent:'center',alignItems:'center',gap:'0.2rem'}} className="btn animate-element smallPadding" to={`/dashboard/updateEvent/${Event._id}`}>Edit<BiEditAlt/></Link>
      </div>

    {Event && (
      <div>

        <div className='containerCms'>
          <h6><b>Agenda link as Canva File: </b>{Event.agendaCanvaLink}</h6>
        </div>
        <div className='containerCms' style={{display:'flex'}}>
          <h6><b>Event Banner Image: </b></h6>
          <img src={Event.eventBannerImage} alt="Event Banner" style={{ width: '10vw', height: '10vh' }} />
        </div>

      </div>
    )}

  </div>

  )
}

export default EventCmsPage