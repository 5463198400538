import './PreviousCT.css'
import { Link } from "react-router-dom";
import GetHomeState from '../../Utils/GetHomeState'
import GetColorState from '../../Utils/GetColorState'
import GetPrevYearSpeakersState from '../../Utils/GetPrevYearSpeakerState'


const PreviousCT = () => {
  const { Home } = GetHomeState();
  const { Color } = GetColorState();
  const { PrevYearSpeakers } = GetPrevYearSpeakersState();

  return (
  <div style={{backgroundColor:Color.primaryBackgroundColor, color:Color.primaryTextColor}} className='flex_row_c previousCT'>

      <div className='flex_row_c text_previousCT'>
        <h4>{Home.previousHeading}</h4>
        <p>{Home.previousContent}</p>
      </div>
      <div className="PreviousCT_button">
        <Link to='https://curatetomorrow2022.fori.co/' style={{border:`2px solid ${Color.secondaryBackgroundColor}`, color:Color.secondaryBackgroundColor}} className='secondary_btn'>Watch the story</Link>
      </div>
      {PrevYearSpeakers.length > 0 ? (
      <div className='speaker2022'>
          {PrevYearSpeakers.map((speaker,index)=>{
            return(
              <div className='speaker22Div' key={index}>
                  <div className='speakerimg22'>
                    <img src={speaker.speakerImgDash}></img>
                  </div>
                <small>{speaker.name}</small>
              </div>
            )
          })}
      </div>
          ):<h5 style={{textAlign:"center"}}>TBH Soon</h5>}
      <Link style={{color:Color.secondaryBackgroundColor}} className='secondary_btn prevBtn' to="https://curatetomorrow2022.fori.co/">And More</Link>
  </div>
  )
}
export default PreviousCT