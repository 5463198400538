import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const initialState = {
    message:'',
    isLoading:false,
    isError:false,
    isSuccess:false
}


////////////////////////////contactForm//////////////
export const contactForm  = createAsyncThunk("contactForm/postContactForm", async(data, thunkAPI)=>{
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/contactForm`
        const response = await axios.post(API_URL, data )
        const responseData =  await response.data
        return responseData

    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})


export const contactFormSlice = createSlice({
    name:'contactFormSlice',
    initialState,
    reducers:{
        reset: (state)=> initialState,
    },
    extraReducers:(builder)=>{
        builder
        .addCase(contactForm.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(contactForm.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.isSuccess =  true
            state.message = action.payload.message;
            toast(action.payload.message)
        })
        .addCase(contactForm.rejected, (state, action)=>{
            state.isError=true;
            state.isLoading = false;
            state.message = action.payload.message;
        })
    }
})


export const {reset,filterState} = contactFormSlice.actions
export default contactFormSlice.reducer