import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GetAllHome } from '../features/HomeCmsSlice/HomeCmsSlice'

function GetHomeState() {
    const { Home, isSuccess } = useSelector((store) => store.homeCms);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GetAllHome());
    }, [isSuccess, dispatch]);
return { Home, isSuccess };
}
export default GetHomeState;