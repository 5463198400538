import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import './SideBar.css'
import GetHomeState from '../../Utils/GetHomeState'

const SideBar = () => {
  const { Home } = GetHomeState();
  const location = useLocation();
  // let active = '';
  let [active, setActive] = useState( (location.pathname.includes("delegate"))? {
    active : "delegate"
  }: {
    active : "sponsor"
  } 
)


  if (location.pathname.includes("speaker")) {
    active = "speaker";
  // } else if (location.pathname.includes("sponsor")) {
  //   active = "sponsor";
  // } else if (location.pathname.includes("delegate")) {
  //   active = "delegate";
  } else if (location.pathname.includes("partner")){
    active = "partner";
  } else if (location.pathname.includes("home")){
    active = "home";
  } else if (location.pathname.includes("about")){
    active = "about";
  } else if (location.pathname.includes("footer")){
    active = "footer";
  } else if (location.pathname.includes("delegateTicket")){
    active = "delegateTicket";
  } else if (location.pathname.includes("sponsorTicket")){
    active = "sponsorTicket";
  } else if (location.pathname.includes("event")){
    active = "event";
  } else if (location.pathname.includes("color")){
    active = "color";
  }




  return (
    <div className='SideBarDash'>
      <div className='SideBar_divDash'>
      <div className='SideBar_imgDash'>
          <img src={Home.eventLogo} alt="" />
      </div>

        <div className='SideBar_LinksDash'>
            <h5>Update Content</h5>
            <Link to="/dashboard/home" className={active==="home"?"activeDash":"deactiveDash"} onClick={()=>setActive("home")}>HOME</Link>
            <Link to="/dashboard/color" className={active==="color"?"activeDash":"deactiveDash"} onClick={()=>setActive("color")}>COLOR</Link>
            <Link to="/dashboard/about" className={active==="about"?"activeDash":"deactiveDash"} onClick={()=>setActive("about")}>ABOUT</Link>
            <Link to="/dashboard/footer" className={active==="footer"?"activeDash":"deactiveDash"} onClick={()=>setActive("footer")}>FOOTER</Link>
            <Link to="/dashboard/event" className={active==="event"?"activeDash":"deactiveDash"} onClick={()=>setActive("event")}>EVENT</Link>
            <Link to="/dashboard/speaker" className={active==="speaker"?"activeDash":"deactiveDash"} onClick={()=>setActive("speaker")}>SPEAKER</Link>
            <Link to="/dashboard/partner" className={active==="partner"?"activeDash":"deactiveDash"} onClick={()=>setActive("partner")}>PARTNER</Link>
            <Link to="/dashboard/prevYearSpeaker" className={active==="prevYearSpeaker"?"activeDash":"deactiveDash"} onClick={()=>setActive("prevYearSpeaker")}>PREVIOUS SPEAKER</Link>
            <Link to="/dashboard/delegateTicket" className={active==="delegateTicket"?"activeDash":"deactiveDash"} onClick={()=>setActive("delegateTicket")}>DELEGATE TICKET</Link>
            <Link to="/dashboard/sponsorTicket" className={active==="sponsorTicket"?"activeDash":"deactiveDash"} onClick={()=>setActive("sponsorTicket")}>SPONSOR TICKET</Link>
            <h5>Registrations</h5>
            <Link to="/dashboard/delegate" className={active==="delegate"?"activeDash":"deactiveDash"} onClick={()=>setActive("delegate")}>All REGISTERED DELEGATES</Link>
            <Link to="/dashboard/sponsor" className={active==="sponsor"?"activeDash":"deactiveDash"} onClick={()=>setActive("sponsor")}>All REGISTERED SPONSORS</Link>
        </div>
      </div>    
    </div>
  )
}

export default SideBar