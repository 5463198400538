import React, { useEffect,useState } from 'react'
import { useSelector , useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import {toast} from 'react-toastify'
import { useLocation, useNavigate } from 'react-router-dom';
import {UpdateAllAbout,reset, GetAllAbout} from '../../features/AboutCmsSlice/AboutCmsSlice'



const UpdateAbout = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location  = useLocation();
    const id = location.pathname.substring(location.pathname.lastIndexOf('/')+1)
    const [data, setData] = useState({
        aboutImg1: null,
        aboutImg2: null,
        theAboutHeading: '',
        theAboutContent:'',
        replayAboutHeading: '',
        replayAboutContent: '',
        collabHeading: '',
        collabContent: '',
    });

const handleChange =(e)=>{
            setData({ ...data, [e.target.id]: e.target.value });
        }

const sendForm = (e)=>{
    e.preventDefault()
    if (data.theAboutHeading !== '' && data.theAboutContent !== '' && data.replayAboutHeading !== '' && data.replayAboutContent !== '' && data.collabHeading !== '' && data.collabContent !== '' && data.aboutContent !== '' && data.previousHeading !== '' && data.previousContent !== '' && data.motiveHeading !== '' && data.motiveContent !== '' && data.venueAddress !== '' && data.eventLogo !== null && data.companyLogo !== null) {
        const formData = new FormData();
        formData.append('theAboutHeading', data.theAboutHeading);
        formData.append('theAboutContent', data.theAboutContent);
        formData.append('replayAboutHeading', data.replayAboutHeading);
        formData.append('replayAboutContent', data.replayAboutContent);
        formData.append('collabHeading', data.collabHeading);
        formData.append('collabContent', data.collabContent);

        if (data.aboutImg1 !== null || data.aboutImg2 !== null) {
            formData.append('aboutImg1', data.aboutImg1);
            formData.append('aboutImg2', data.aboutImg2);
            dispatch(UpdateAllAbout({ formData, id }))
            .then(() => {
              // After creating the speaker, call GetAllAbout API to refresh the list
              dispatch(GetAllAbout());
              navigate('/dashboard/About');
            })
            .catch((error) => {
              // Handle any errors that occur during speaker creation
              toast.error('Error updating about: ' + error.message);
            });
        } else {
          toast('Please upload the profile image.');
        }
    
      } else {
        toast('Please fill out the form completely.');
      }
    }
let {About, isSuccess} = useSelector((store)=>store.aboutCms)
About = About ? About : null;

useEffect(()=>{
    dispatch(GetAllAbout())
    if(isSuccess && About){
        setData({ theAboutHeading:About.theAboutHeading, theAboutContent:About.theAboutContent, replayAboutHeading:About.replayAboutHeading, replayAboutContent:About.replayAboutContent, collabHeading:About.collabHeading, collabContent:About.collabContent, aboutImg1:null, aboutImg2:null })
    }
},[isSuccess])

return (
    <form className='CreateCareer'> 
    <div className="form_left">
        <TextField id="theAboutHeading" value={data.theAboutHeading} label="About Heading" variant="outlined" onChange={(e)=>{handleChange(e)}}/>
        <TextField id="theAboutContent" value={data.theAboutContent} label="About Content" variant="outlined" onChange={(e)=>{handleChange(e)}}/>
        <TextField id="replayAboutHeading" value={data.replayAboutHeading} label="Replay Event Heading" variant="outlined" onChange={(e)=>{handleChange(e)}}/>
        <TextField id="replayAboutContent" value={data.replayAboutContent} label="Replay Event Content" variant="outlined" onChange={(e)=>{handleChange(e)}}/>
        <TextField id="collabHeading" value={data.collabHeading} label="Collaboration Heading" variant="outlined" onChange={(e)=>{handleChange(e)}}/>
        <TextField id="collabContent" value={data.collabContent} label="Collaboration Content" variant="outlined" onChange={(e)=>{handleChange(e)}}/>
        <div style={{display:'flex',flexDirection:'column' , gap:'0.5rem'}}>
            <label htmlFor="aboutImg1">Location Image</label>
            <input id="aboutImg1" label="Location Image" type="file" accept="image/*" onChange={(e)=>setData({...data,["aboutImg1"]:e.target.files[0]})}/>
        </div>
        <div style={{display:'flex',flexDirection:'column' , gap:'0.5rem'}}>
            <label htmlFor="aboutImg2">Event Image</label>
            <input id="aboutImg2" label="Event Image" type="file" accept="image/*" onChange={(e)=>setData({...data,["aboutImg2"]:e.target.files[0]})}/>
        </div>
    </div>
    <div style={{ textAlign: 'left' }}>
        <button className='btn' onClick={(e)=>sendForm(e)}>Submit</button>
    </div>
</form>
)
}
export default UpdateAbout