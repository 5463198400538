import React, { useEffect } from 'react';
import { useSelector , useDispatch } from 'react-redux';
import {GetAllAbout} from '../../features/AboutCmsSlice/AboutCmsSlice'
import {BiEditAlt} from 'react-icons/bi'
import { Link } from 'react-router-dom';


const AboutPage = () => {
  const dispatch = useDispatch()

  const {About} = useSelector((store)=>store.aboutCms)

  useEffect(()=>{
    dispatch(GetAllAbout())
  },[dispatch])

return (
    <div className='home_pageCms'>
      <div style={{display:'flex', justifyContent:'flex-end',alignItems:'center',gap:'0.2rem',textAlign:'right', marginBottom:'1.5rem'}}>
        <Link style={{display:'flex', justifyContent:'center',alignItems:'center',gap:'0.2rem'}} className="btn animate-element smallPadding" to={`/dashboard/updateabout/${About._id}`}>Edit<BiEditAlt/></Link>
      </div>

    {About && (
      <div>

        <div className='containerCms'>
          <h6><b>About Heading: </b>{About.theAboutHeading}</h6>
        </div>
        <div className='containerCms'>
          <h6><b>About Content: </b>{About.theAboutContent}</h6>
        </div>
        <div className='containerCms'>
          <h6><b>Heading of Replay the Event : </b>{About.replayAboutHeading}</h6>
        </div>
        <div className='containerCms'>
          <h6><b>Replay Event Content: </b>{About.replayAboutContent}</h6>
        </div>
        <div className='containerCms'>
          <h6><b>Collaboration Heading: </b>{About.collabHeading}</h6>
        </div>
        <div className='containerCms'>
          <h6><b>Collaboration Content: </b>{About.collabContent}</h6>
        </div>
        <div className='containerCms' style={{display:'flex'}}>
          <h6><b>Location Image: </b></h6>
          <img src={About.aboutImg1} alt="location picture" style={{ width: '10vw', height: '10vh' }} />
        </div>
        <div className='containerCms' style={{display:'flex'}}>
          <h6><b>Event Location Image: </b></h6>
          <img src={About.aboutImg2} alt="location picture" style={{ width: '10vw', height: '10vh' }} />
        </div>

      </div>
    )}

  </div>

  )
}

export default AboutPage