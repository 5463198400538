import React, { useEffect } from 'react';
import { useSelector , useDispatch } from 'react-redux';
import {GetAllPartners , filterState,DeleteSinglePartner} from '../../features/PartnerCmsSlice/PartnerCmsSlice'
import DataTable from '../../CmsComponents/DataTablePartner/DataTablePartner';
import {RiDeleteBin7Line} from 'react-icons/ri'

const PartnerPage = () => {
  const dispatch = useDispatch()

  const deleteSpeaker=async(id)=>{
    dispatch(DeleteSinglePartner(id));
    dispatch(filterState(id))
    
  }
  const PartnerColumns = [
    {
      field: 'id', 
      headerName: 'Sr.No',
      width: 170,
    },
    {
        field: 'partnerImg',
        headerName: 'Partner Logo',
        width: 900,
        renderCell: (params) => (
          <img
            src={params.value} // Assuming 'speakerImgDash' contains the image URL
            alt={params.row.name} // You can set the alt text to the speaker's name or any other meaningful description
            style={{ width: '100px', height: 'auto' }}
          />
        ),
      },
        {field: "Action",headerName: "Action",width: 120, renderCell: (params) => 
    <div style={{display:"flex", alignItems:"center", gap:"1rem"}}>
      <button style={{fontSize:"1.5rem", color:"#3166BB", cursor:"pointer", background:'none', border:'none'}} onClick={()=>deleteSpeaker(params.row._id)}><RiDeleteBin7Line/></button>
    </div>},
  
  ];
  const {Partners} = useSelector((store)=>store.partnerCms)
  useEffect(()=>{
    dispatch(GetAllPartners())
    
  },[dispatch])
  



  return (
    <div>
    {Partners ? (
      <DataTable columns={PartnerColumns} data={Partners} link="/dashboard/create-partner" />
    ) : (
      <DataTable columns={PartnerColumns} data={[]} link="/dashboard/create-partner" />
    )}

    </div>
  )
}

export default PartnerPage