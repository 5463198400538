import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const initialState = {
    Event:'',
    message:'',
    isLoading:false,
    isError:false,
    isSuccess:false,
}


////////////////////////////Get All Event////////////////
export const GetAllEvent = createAsyncThunk('Event/getCmsEvent', async(_, thunkAPI)=>{
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/getCmsEvent`
        const response = await axios.get(API_URL)
 
        return response.data
 
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})


export const UpdateAllEvent = createAsyncThunk('Event/UpdateAllEvent', async({formData,id}, thunkAPI)=>{

    try{
        const token = thunkAPI.getState().auth.user.token
        const config ={
           headers:{
               Authorization: `Bearer ${token}`
           }
       }
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/updateCmsEvent/${id}`
        const response = await axios.patch(API_URL, formData, config)
        return response.data
 
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})



export const EventSlice = createSlice({
    name:'EventSlice',
    initialState,
    reducers:{
        reset: (state)=> initialState,
        filterState:(state,action)=>{            
            state.Event = state.Event.filter((item) => item._id !== action.payload)
        }
    },
    extraReducers:(builder)=>{
        builder
        .addCase(GetAllEvent.pending, (state, action)=>{
            state.isLoading = true
        })
        .addCase(GetAllEvent.fulfilled, (state, action)=>{
            state.isLoading = false
            state.message = action.payload.message
            state.isSuccess = true
            if(action.payload.success===true){
                state.Event = action.payload.cmsEvent
            }
        })
        .addCase(GetAllEvent.rejected, (state, action)=>{
            state.isLoading = false
            state.isError=true
            state.message = action.payload
            state.Event={_id:"",eventBannerImage:"",agendaCanvaLink:""}
        })

        .addCase(UpdateAllEvent.pending, (state, action)=>{
            state.isLoading = true
        })
        .addCase(UpdateAllEvent.fulfilled, (state, action)=>{
            state.isSuccess=true
            state.isLoading = false
            state.message = action.payload.message
                if(action.payload.success===true){
                    state.Event = action.payload.updatedEventPage
                    toast(state.message)
            }
        })
        .addCase(UpdateAllEvent.rejected, (state, action)=>{
            state.isSuccess=false
            state.isLoading = false
            state.isError=true
            state.message = action.payload
            toast(action.payload)
            state.Event={_id:"",eventBannerImage:"",agendaCanvaLink:""}
        })
    }
})


export const {reset,filterState} = EventSlice.actions
export default EventSlice.reducer