// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SideBarDash{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 1rem 2rem 1rem;
    /* width: 15vw; */
    height: 100vh;
    background-color: rgba(0,0,0,0.6);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.SideBar_divDash{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
}

.SideBar_imgDash{
    width: 100%;
}
.SideBar_imgDash>img{
  width: 100%;
}

.SideBar_LinksDash{
    display: flex;
    flex-direction: column;
    width: auto;
    gap: 0rem;
    box-sizing: border-box;
    background-color: transparent;
}

.deactiveDash{
  padding: 0.5rem 0rem !important;
  color:  var(--t-white) !important;

}
.activeDash{
  padding: 0.5rem 0.5rem;
  background-color: var(--bg-dpink) !important;
  color: var(--t-white) !important;
}
@media only screen and (max-width: 600px) {
  .SideBarDash{
    display: none;
  }

}`, "",{"version":3,"sources":["webpack://./src/CmsComponents/SideBar/SideBar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,mBAAmB;IACnB,4BAA4B;IAC5B,iBAAiB;IACjB,aAAa;IACb,iCAAiC;IACjC,iDAAiD;AACrD;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,WAAW;AACf;AACA;EACE,WAAW;AACb;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,SAAS;IACT,sBAAsB;IACtB,6BAA6B;AACjC;;AAEA;EACE,+BAA+B;EAC/B,iCAAiC;;AAEnC;AACA;EACE,sBAAsB;EACtB,4CAA4C;EAC5C,gCAAgC;AAClC;AACA;EACE;IACE,aAAa;EACf;;AAEF","sourcesContent":[".SideBarDash{\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    align-items: center;\n    padding: 2rem 1rem 2rem 1rem;\n    /* width: 15vw; */\n    height: 100vh;\n    background-color: rgba(0,0,0,0.6);\n    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;\n}\n.SideBar_divDash{\n    display: flex;\n    flex-direction: column;\n    gap: 2rem;\n    justify-content: center;\n    align-items: center;\n}\n\n.SideBar_imgDash{\n    width: 100%;\n}\n.SideBar_imgDash>img{\n  width: 100%;\n}\n\n.SideBar_LinksDash{\n    display: flex;\n    flex-direction: column;\n    width: auto;\n    gap: 0rem;\n    box-sizing: border-box;\n    background-color: transparent;\n}\n\n.deactiveDash{\n  padding: 0.5rem 0rem !important;\n  color:  var(--t-white) !important;\n\n}\n.activeDash{\n  padding: 0.5rem 0.5rem;\n  background-color: var(--bg-dpink) !important;\n  color: var(--t-white) !important;\n}\n@media only screen and (max-width: 600px) {\n  .SideBarDash{\n    display: none;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
